// import { MsalAuthProvider, LoginType } from 'react-aad-msal';
// import { MsalProvider} from '@azure/msal-react';
import { Configuration, RedirectRequest } from "@azure/msal-browser";

// import { Configuration } from '@azure/msal-browser';

// Msal Configurations
export const msalConfig: Configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
    clientId: '9fe71369-8983-45a3-9eab-95e8d962da16',
    postLogoutRedirectUri: "https://inuci.deloitte.com/logout",
    // validateAuthority: true,
    redirectUri: 'https://inuci.deloitte.com/',
    navigateToLoginRequestUrl: true,
    // clientId: 'bcc57e68-9198-4a82-a6e6-ee45a833812d',
    // postLogoutRedirectUri: "https://d1s7wfk8vpimf6.cloudfront.net/logout",
    // validateAuthority: true,
    // redirectUri: 'https://d1s7wfk8vpimf6.cloudfront.net',
    // navigateToLoginRequestUrl: true,




  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
    secureCookies:true
  }
};

export const loginRequest: RedirectRequest = {
  scopes: ["User.Read"]
};
