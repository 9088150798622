import React, { useState } from "react";
import Header from "../Header/LoginPageHeader/header";
import withNavigation from "../../components/Nav/withNavigation";
import Button from "@material-ui/core/Button";
import LoginFooter from "../Footer/LoginFooter";
import axios from "../../components/api/axios";
import "./passwordReset.css";
import { useNavigate } from "react-router-dom";

const PasswordReset = () => {
  const HeaderComponentWithNavigation = withNavigation(Header);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const validatePassword = (event) => {
    setPassword(event.target.value);

    // const errors = {};

    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;

    // if (!regex.test(newPassword.value)) {
    //   errors.requirementUnmet = "New password does not meet requirements.";
    // }

    // if (newPassword.value !== confirmPassword.value) {
    //   errors.passwordMismatch = "Entered passwords do not match.";
    // }

    // if (!Object.keys(errors).length) {
    //   alert("password validation successful.");
    //   setError(null);
    //   return;
    // }
  };
  const validateConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };
  const makeStylesForm = {
    padding: "17 30",
    marginTop: 50,
    borderColor: "black",
    color: "black",
    fontSize: 14,
    fontFamily: "Arial, Helvetica, sans-serif",
    width: 80,
    height: 30,
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    top: -20,
    left: 100,
    fontWeight: "bold",
  };
  const payload = {
    password,
    confirmPassword,
  };
  const resetClick = () => {
    const errors = {};

    if (confirmPassword.value !== password.value) {
      errors.passwordMismatch = "Entered passwords do not match.";
    }

    const RESET_URL = "/customer/user/passwordReset";
    try {
      const response = axios.post(RESET_URL, JSON.stringify({ payload }));
      console.log(response);
      if (response.status === 200) navigate(`/passwordSuccess`);
      window.location.reload(false);
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <div>
      <HeaderComponentWithNavigation />
      <div className="reset">
        <fieldset className="field">
          <form className="reset_form" autoComplete="off">
            <h1>Password Reset</h1>
            <label htmlFor="username">
              Password
              <input
                className="inputBox"
                type="password"
                value={password}
                onChange={validatePassword}
                required
              />
            </label>
            {passwordError && <p className="error-msg">Invalid Password</p>}

            <label htmlFor="username">
              Confirm Password
              <input
                className="inputBoxPass"
                type="password"
                value={confirmPassword}
                onChange={validateConfirmPassword}
                required
              />
            </label>
            {passwordError && <p className="error-msg">Invalid Password</p>}

            <Button
              type="submit"
              variant="contained"
              style={makeStylesForm}
              className="login_btn"
              onClick={resetClick}
            >
              <span>Reset</span>
            </Button>
          </form>
        </fieldset>
      </div>
      <LoginFooter />
    </div>
  );
};

export default PasswordReset;
