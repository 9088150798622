import React from "react";
import { useState, useRef, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import { useForm } from "../../../helpers/validations/useForm";
import {
  getProjectDates,
  updateProjectDates,
} from "../../../store/actions/Project";
import "./SubProjectDates.css";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { ProfileType } from "../../../utility/commonTypes";
import moment from "moment";

interface ProjectDateInterface {
  id: any;
  total_project_weeks: number;
  project_id: any;
  plan_phase_start_date: any;
  plan_phase_end_date: any;
  architect_phase_start_date: any;
  architect_phase_end_date: any;
  cp_phase_start_date: any;
  cp_phase_end_date: any;
  requirement_phase_start_date: any;
  requirement_phase_end_date: any;
  design_phase_start_date: any;
  design_phase_end_date: any;
  build_phase_start_date: any;
  build_phase_end_date: any;
  test_phase_start_date: any;
  end_to_end_phase_start_date: any;
  end_to_end_phase_end_date: any;
  parallel_testing_start_date: any;
  parallel_testing_end_date: any;
  test_phase_end_date: any;
  deploy_phase_start_date: any;
  deploy_phase_end_date: any;
  smoke_test_start_date: any;
  smoke_test_end_date: any;
  original_go_live_date: any;
  original_prod_support_end_date: any;
  go_live_date: any;
  hypercare_end_date: any;
  actionType: any;
}
const SubProjectDates: FC = () => {
  const projectRef = useRef({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [res, setRes] = useState([]);
  const [actionType, setActionType] = useState("create");
  const [dateId, setDateId] = useState(null);
  const [projectID, setProjectId] = useState(null);
  const location = useLocation();
  const params = useParams();
  //const stateData: any = location.state;
  const stateData: any = params;
  const [messages, setMessage] = useState([]);
  const [open, setOpen] = useState(false);
 const[response,setResponse]=useState("")
 
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const {
    handleSubmit,
    handleChange,
    data: projectDate,
    setData,
    errors,
  } = useForm<ProjectDateInterface>({
    validations: {
      plan_phase_start_date: {
        required: {
          value: true,
          message: "Required field",
        },
      },

      go_live_date: {
        required: {
          value: true,
          message: "Required field",
        },
      },
      hypercare_end_date: {
        required: {
          value: true,
          message: "Required field",
        },
      },
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      setLoader(true);
      dispatch(
        updateProjectDates(
          {
            payload: {
              id: dateId,
              total_project_weeks: projectDate?.total_project_weeks || null,
              project_id: stateData?.subProjectId,
              plan_phase_start_date: projectDate?.plan_phase_start_date || null,
              plan_phase_end_date: projectDate?.plan_phase_end_date || null,
              architect_phase_start_date:
                projectDate?.architect_phase_start_date || null,
              architect_phase_end_date:
                projectDate?.architect_phase_end_date || null,
              cp_phase_start_date: projectDate?.cp_phase_start_date || null,
              cp_phase_end_date: projectDate?.cp_phase_end_date || null,
              requirement_phase_start_date:
                projectDate?.requirement_phase_start_date || null,
              requirement_phase_end_date:
                projectDate?.requirement_phase_end_date || null,
              design_phase_start_date:
                projectDate?.design_phase_start_date || null,
              design_phase_end_date: projectDate?.design_phase_end_date || null,
              build_phase_start_date:
                projectDate?.build_phase_start_date || null,
              build_phase_end_date: projectDate?.build_phase_end_date || null,
              test_phase_start_date: projectDate?.test_phase_start_date || null,
              end_to_end_phase_start_date:
                projectDate?.end_to_end_phase_start_date || null,
              end_to_end_phase_end_date:
                projectDate?.end_to_end_phase_end_date || null,
              parallel_testing_start_date:
                projectDate?.parallel_testing_start_date || null,
              parallel_testing_end_date:
                projectDate?.parallel_testing_end_date || null,
              test_phase_end_date: projectDate?.test_phase_end_date || null,
              deploy_phase_start_date:
                projectDate?.deploy_phase_start_date || null,
              deploy_phase_end_date: projectDate?.deploy_phase_end_date || null,
              smoke_test_start_date: projectDate?.smoke_test_start_date || null,
              smoke_test_end_date: projectDate?.smoke_test_end_date || null,
              original_go_live_date: projectDate?.original_go_live_date || null,
              original_prod_support_end_date:
                projectDate?.original_prod_support_end_date || null,
              go_live_date: projectDate?.go_live_date || null,
              hypercare_end_date: projectDate?.hypercare_end_date || null,
              actionType: actionType,
            },
          },

          onSignUpProjectDatesCallback
        )
      );
    },
  });

  const formatProjectDates = (dt: any) => {
    if (dt !== null && dt !== "" && dt !== undefined) {
      let responseDate = new Date(dt);

      return (
        responseDate.getFullYear() +
        "-" +
        ("0" + (responseDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + responseDate.getDate()).slice(-2)
      );
    }
  };
  let res1=""
  const currentTabData=()=>{
    dispatch(
      getProjectDates({project_id:stateData?.subProjectId}, function (
          res: any
        ) {
          console.log({ res });
        //  setTimeout(()=>{
          //  setResponse(res)
           res1=res
        //  },1000)
          // setResponse((res) => {
          //   console.log(res); // "React is awesome!"
            
          //   return res;
          // });
          setDateId(res?.id);
          setProjectId(res?.project_id);
          res?.id && setActionType("update");
          console.log({ res });
          console.log("inside block is",res1)
  
          // setData({
          //   ...res,
          //   total_project_weeks: res?.total_project_weeks,
          //   project_id: res?.project_id,
          //   plan_phase_start_date: formatProjectDates(res?.plan_phase_start_date),
          //   plan_phase_end_date: formatProjectDates(res?.plan_phase_end_date),
          //   architect_phase_start_date: formatProjectDates(
          //     res?.architect_phase_start_date
          //   ),
          //   architect_phase_end_date: formatProjectDates(
          //     res?.architect_phase_end_date
          //   ),
          //   cp_phase_start_date: formatProjectDates(res?.cp_phase_start_date),
          //   cp_phase_end_date: formatProjectDates(res?.cp_phase_end_date),
          //   requirement_phase_start_date: formatProjectDates(
          //     res?.requirement_phase_start_date
          //   ),
          //   requirement_phase_end_date: formatProjectDates(
          //     res?.requirement_phase_end_date
          //   ),
          //   design_phase_start_date: formatProjectDates(
          //     res?.design_phase_start_date
          //   ),
          //   design_phase_end_date: formatProjectDates(res?.design_phase_end_date),
          //   build_phase_start_date: formatProjectDates(
          //     res?.build_phase_start_date
          //   ),
          //   build_phase_end_date: formatProjectDates(res?.build_phase_end_date),
          //   test_phase_start_date: formatProjectDates(res?.test_phase_start_date),
          //   end_to_end_phase_start_date: formatProjectDates(
          //     res?.end_to_end_phase_start_date
          //   ),
          //   end_to_end_phase_end_date: formatProjectDates(
          //     res?.end_to_end_phase_end_date
          //   ),
          //   parallel_testing_start_date: formatProjectDates(
          //     res?.parallel_testing_start_date
          //   ),
          //   parallel_testing_end_date: formatProjectDates(
          //     res?.parallel_testing_end_date
          //   ),
          //   test_phase_end_date: formatProjectDates(res?.test_phase_end_date),
          //   deploy_phase_start_date: formatProjectDates(
          //     res?.deploy_phase_start_date
          //   ),
          //   deploy_phase_end_date: formatProjectDates(res?.deploy_phase_end_date),
          //   smoke_test_start_date: formatProjectDates(res?.smoke_test_start_date),
          //   smoke_test_end_date: formatProjectDates(res?.smoke_test_end_date),
          //   original_go_live_date: formatProjectDates(res?.original_go_live_date),
          //   original_prod_support_end_date: formatProjectDates(
          //     res?.original_prod_support_end_date
          //   ),
          //   go_live_date: formatProjectDates(res?.go_live_date),
          //   hypercare_end_date: formatProjectDates(res?.hypercare_end_date),
          // });
          console.log("response is",res1)
          console.log("response is",typeof(res1))
      
         res1 === null ?
            dispatch(
            
            getProjectDates({project_id:stateData?.masterProjectId}, function (
              res1: any
            ) {
         setDateId(res1?.id);
          setProjectId(res1?.project_id);
          res1?.id && setActionType("update");
          console.log({ res1 });
          console.log("inside block is",res1)
  
          setData({
            ...res1,
            total_project_weeks: res1?.total_project_weeks,
            project_id: res1?.project_id,
            plan_phase_start_date: formatProjectDates(res1?.plan_phase_start_date),
            plan_phase_end_date: formatProjectDates(res1?.plan_phase_end_date),
            architect_phase_start_date: formatProjectDates(
              res1?.architect_phase_start_date
            ),
            architect_phase_end_date: formatProjectDates(
              res1?.architect_phase_end_date
            ),
            cp_phase_start_date: formatProjectDates(res1?.cp_phase_start_date),
            cp_phase_end_date: formatProjectDates(res1?.cp_phase_end_date),
            requirement_phase_start_date: formatProjectDates(
              res1?.requirement_phase_start_date
            ),
            requirement_phase_end_date: formatProjectDates(
              res1?.requirement_phase_end_date
            ),
            design_phase_start_date: formatProjectDates(
              res1?.design_phase_start_date
            ),
            design_phase_end_date: formatProjectDates(res1?.design_phase_end_date),
            build_phase_start_date: formatProjectDates(
              res1?.build_phase_start_date
            ),
            build_phase_end_date: formatProjectDates(res1?.build_phase_end_date),
            test_phase_start_date: formatProjectDates(res1?.test_phase_start_date),
            end_to_end_phase_start_date: formatProjectDates(
              res1?.end_to_end_phase_start_date
            ),
            end_to_end_phase_end_date: formatProjectDates(
              res1?.end_to_end_phase_end_date
            ),
            parallel_testing_start_date: formatProjectDates(
              res1?.parallel_testing_start_date
            ),
            parallel_testing_end_date: formatProjectDates(
              res1?.parallel_testing_end_date
            ),
            test_phase_end_date: formatProjectDates(res1?.test_phase_end_date),
            deploy_phase_start_date: formatProjectDates(
              res1?.deploy_phase_start_date
            ),
            deploy_phase_end_date: formatProjectDates(res1?.deploy_phase_end_date),
            smoke_test_start_date: formatProjectDates(res1?.smoke_test_start_date),
            smoke_test_end_date: formatProjectDates(res1?.smoke_test_end_date),
            original_go_live_date: formatProjectDates(res1?.original_go_live_date),
            original_prod_support_end_date: formatProjectDates(
              res1?.original_prod_support_end_date
            ),
            go_live_date: formatProjectDates(res1?.go_live_date),
            hypercare_end_date: formatProjectDates(res1?.hypercare_end_date),
          });
        })
            ):
            dispatch(
            
              getProjectDates({project_id:stateData?.subProjectId}, function (
                res1: any
              ) {
           setDateId(res1?.id);
            setProjectId(res1?.project_id);
            res1?.id && setActionType("update");
    
            setData({
              ...res1,
              total_project_weeks: res1?.total_project_weeks,
              project_id: res1?.project_id,
              plan_phase_start_date: formatProjectDates(res1?.plan_phase_start_date),
              plan_phase_end_date: formatProjectDates(res1?.plan_phase_end_date),
              architect_phase_start_date: formatProjectDates(
                res1?.architect_phase_start_date
              ),
              architect_phase_end_date: formatProjectDates(
                res1?.architect_phase_end_date
              ),
              cp_phase_start_date: formatProjectDates(res1?.cp_phase_start_date),
              cp_phase_end_date: formatProjectDates(res1?.cp_phase_end_date),
              requirement_phase_start_date: formatProjectDates(
                res1?.requirement_phase_start_date
              ),
              requirement_phase_end_date: formatProjectDates(
                res1?.requirement_phase_end_date
              ),
              design_phase_start_date: formatProjectDates(
                res1?.design_phase_start_date
              ),
              design_phase_end_date: formatProjectDates(res1?.design_phase_end_date),
              build_phase_start_date: formatProjectDates(
                res1?.build_phase_start_date
              ),
              build_phase_end_date: formatProjectDates(res1?.build_phase_end_date),
              test_phase_start_date: formatProjectDates(res1?.test_phase_start_date),
              end_to_end_phase_start_date: formatProjectDates(
                res1?.end_to_end_phase_start_date
              ),
              end_to_end_phase_end_date: formatProjectDates(
                res1?.end_to_end_phase_end_date
              ),
              parallel_testing_start_date: formatProjectDates(
                res1?.parallel_testing_start_date
              ),
              parallel_testing_end_date: formatProjectDates(
                res1?.parallel_testing_end_date
              ),
              test_phase_end_date: formatProjectDates(res1?.test_phase_end_date),
              deploy_phase_start_date: formatProjectDates(
                res1?.deploy_phase_start_date
              ),
              deploy_phase_end_date: formatProjectDates(res1?.deploy_phase_end_date),
              smoke_test_start_date: formatProjectDates(res1?.smoke_test_start_date),
              smoke_test_end_date: formatProjectDates(res1?.smoke_test_end_date),
              original_go_live_date: formatProjectDates(res1?.original_go_live_date),
              original_prod_support_end_date: formatProjectDates(
                res1?.original_prod_support_end_date
              ),
              go_live_date: formatProjectDates(res1?.go_live_date),
              hypercare_end_date: formatProjectDates(res1?.hypercare_end_date),
            });
          })
              ) 
      }
        // }
        )
       );
  
  
  }
  useEffect(() => {
    currentTabData();
    // if(res11 === null)
    // {
    //   console.log("response is",res)

    //   dispatch(
    //     getProjectDates({ project_id: stateData?.masterProjectId }, function (
    //       res: any
    //     ) {
    //       console.log({ res });
    //       setDateId(res?.id);
    //       setProjectId(res?.project_id);
    //       res?.id && setActionType("update");
    //       console.log({ res });
    //       setData({
    //         ...res,
    //         total_project_weeks: res?.total_project_weeks,
    //         project_id: res?.project_id,
    //         plan_phase_start_date: formatProjectDates(res?.plan_phase_start_date),
    //         plan_phase_end_date: formatProjectDates(res?.plan_phase_end_date),
    //         architect_phase_start_date: formatProjectDates(
    //           res?.architect_phase_start_date
    //         ),
    //         architect_phase_end_date: formatProjectDates(
    //           res?.architect_phase_end_date
    //         ),
    //         cp_phase_start_date: formatProjectDates(res?.cp_phase_start_date),
    //         cp_phase_end_date: formatProjectDates(res?.cp_phase_end_date),
    //         requirement_phase_start_date: formatProjectDates(
    //           res?.requirement_phase_start_date
    //         ),
    //         requirement_phase_end_date: formatProjectDates(
    //           res?.requirement_phase_end_date
    //         ),
    //         design_phase_start_date: formatProjectDates(
    //           res?.design_phase_start_date
    //         ),
    //         design_phase_end_date: formatProjectDates(res?.design_phase_end_date),
    //         build_phase_start_date: formatProjectDates(
    //           res?.build_phase_start_date
    //         ),
    //         build_phase_end_date: formatProjectDates(res?.build_phase_end_date),
    //         test_phase_start_date: formatProjectDates(res?.test_phase_start_date),
    //         end_to_end_phase_start_date: formatProjectDates(
    //           res?.end_to_end_phase_start_date
    //         ),
    //         end_to_end_phase_end_date: formatProjectDates(
    //           res?.end_to_end_phase_end_date
    //         ),
    //         parallel_testing_start_date: formatProjectDates(
    //           res?.parallel_testing_start_date
    //         ),
    //         parallel_testing_end_date: formatProjectDates(
    //           res?.parallel_testing_end_date
    //         ),
    //         test_phase_end_date: formatProjectDates(res?.test_phase_end_date),
    //         deploy_phase_start_date: formatProjectDates(
    //           res?.deploy_phase_start_date
    //         ),
    //         deploy_phase_end_date: formatProjectDates(res?.deploy_phase_end_date),
    //         smoke_test_start_date: formatProjectDates(res?.smoke_test_start_date),
    //         smoke_test_end_date: formatProjectDates(res?.smoke_test_end_date),
    //         original_go_live_date: formatProjectDates(res?.original_go_live_date),
    //         original_prod_support_end_date: formatProjectDates(
    //           res?.original_prod_support_end_date
    //         ),
    //         go_live_date: formatProjectDates(res?.go_live_date),
    //         hypercare_end_date: formatProjectDates(res?.hypercare_end_date),
    //       });
    //     })
    //   );
    //  }
  }, [setData,setResponse]);
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);

  const onSignUpProjectDatesCallback = (data: any, error: any) => {
    setLoader(false);
    if (data === false) {
      setMessage(error);
      setErrorPopup(true);
    } else {
      setSuccessPopup(true);
      setTimeout(() => {
        setSuccessPopup(false)

        window.scrollTo(0,0);
        currentTabData()
      }, 2000);
    }
  };
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-xl-10 col-xxl-7">
          <div className="frame_outer">
            <div className="col-md-12 pull-left  mb-4 vendor_contact">Main</div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label customername">
                      Total Project Weeks
                    </label>
                    <div>
                      <input
                        name="total_project_weeks"
                        id="total_project_weeks"
                        type="text"
                        value={projectDate.total_project_weeks}
                        onChange={handleChange("total_project_weeks")}
                        placeholder="Total Project Weeks"
                        className="form-control"
                        disabled
                      />
                      {errors.total_project_weeks && (
                        <p className="error-text">
                          {errors.total_project_weeks}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Deploy Phase Start Date
                    </label>
                    <input
                      name="deploy_phase_start_date"
                      type="date"
                      value={projectDate.deploy_phase_start_date || ""}
                      // data-date={projectDate?.deploy_phase_start_date  ? moment(projectDate?.deploy_phase_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      onChange={handleChange("deploy_phase_start_date")}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.deploy_phase_start_date && (
                      <p className="error-text">
                        {errors.deploy_phase_start_date}
                      </p>
                    )}
                  </div>
                </div>
 <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Plan Phase Start Date *
                    </label>
                    <div>
                      <input
                        name="plan_phase_start_date"
                        id="plan_phase_start_date"
                        type="date"
                        value={projectDate?.plan_phase_start_date || ""}
                        // data-date={projectDate?.plan_phase_start_date ?moment(projectDate?.plan_phase_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                        data-date-format="DD-MMM-YYYY"
                        onChange={handleChange("plan_phase_start_date")}
                        placeholder="DD-MMM-YYYY"
                        className="form-control"
                        onKeyDown={(e) => {
                          e.preventDefault();
                       }}
                      />
                      {errors.plan_phase_start_date && (
                        <p className="error-text">
                          {errors.plan_phase_start_date}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
               
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Deploy Phase End Date
                    </label>
                    <input
                      name="deploy_phase_end_date"
                      type="date"
                      value={projectDate.deploy_phase_end_date || ""}
                      onChange={handleChange("deploy_phase_end_date")}
                      // data-date={projectDate?.deploy_phase_end_date ? moment(projectDate?.deploy_phase_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.deploy_phase_end_date && (
                      <p className="error-text">
                        {errors.deploy_phase_end_date}
                      </p>
                    )}
                  </div>
                </div>
 <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Plan Phase End Date
                    </label>
                    <input
                      name="plan_phase_end_date"
                      id="plan_phase_end_date"
                      type="date"
                      value={projectDate.plan_phase_end_date || ""}
                      onChange={handleChange("plan_phase_end_date")}
                      // data-date={projectDate?.plan_phase_end_date ?moment(projectDate?.plan_phase_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.plan_phase_end_date && (
                      <p className="error-text">{errors.plan_phase_end_date}</p>
                    )}
                  </div>
                </div>
               
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Smoke Test Start Date
                    </label>
                    <div>
                      <input
                        name="smoke_test_start_date"
                        type="date"
                        value={projectDate.smoke_test_start_date || ""}
                        onChange={handleChange("smoke_test_start_date")}
                        // data-date={projectDate?.smoke_test_start_date ? moment(projectDate?.smoke_test_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                        placeholder="DD-MMM-YYYY"
                        onKeyDown={(e) => {
                          e.preventDefault();
                       }}
                        className="form-control"
                      />
                      {errors.smoke_test_start_date && (
                        <p className="error-text">
                          {errors.smoke_test_start_date}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                 <div className="col-md-6">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-8">
                        <label htmlFor="name" className="form-label">
                          Architect Phase Start Date
                        </label>
                      </div>
                    </div>
                    <input
                      name="architect_phase_start_date"
                      type="date"
                      value={projectDate.architect_phase_start_date || ""}
                      onChange={handleChange("architect_phase_start_date")}
                      // data-date={projectDate?.architect_phase_start_date ?moment(projectDate?.architect_phase_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                      className="form-control"
                    />
                    {errors.architect_phase_start_date && (
                      <p className="error-text">
                        {errors.architect_phase_start_date}
                      </p>
                    )}
                  </div>
                </div>
              
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Smoke Test End Date
                    </label>
                    <input
                      name="smoke_test_end_date"
                      type="date"
                      value={projectDate.smoke_test_end_date || ""}
                      onChange={handleChange("smoke_test_end_date")}
                      // data-date={projectDate?.smoke_test_end_date ?moment(projectDate?.smoke_test_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.smoke_test_end_date && (
                      <p className="error-text">{errors.smoke_test_end_date}</p>
                    )}
                  </div>
                </div>
                  <div className="col-md-6">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-8">
                        <label htmlFor="name" className="form-label">
                          Architect Phase End Date
                        </label>
                      </div>
                    </div>
                    <input
                      name="architect_phase_end_date"
                      type="date"
                      value={projectDate.architect_phase_end_date || ""}
                      onChange={handleChange("architect_phase_end_date")}
                      // data-date={projectDate?.architect_phase_end_date ? moment(projectDate?.architect_phase_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.architect_phase_end_date && (
                      <p className="error-text">
                        {errors.architect_phase_end_date}
                      </p>
                    )}
                  </div>
                </div>
              
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Original Go-Live Date
                    </label>
                    <input
                      name="original_go_live_date"
                      type="date"
                      value={projectDate.original_go_live_date || ""}
                      onChange={handleChange("original_go_live_date")}
                      // data-date={projectDate?.original_go_live_date?moment(projectDate?.original_go_live_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.original_go_live_date && (
                      <p className="error-text">
                        {errors.original_go_live_date}
                      </p>
                    )}
                  </div>
                </div>
                  <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      C&P Phase Start Date
                    </label>
                    <input
                      name="cp_phase_start_date"
                      type="date"
                      value={projectDate.cp_phase_start_date || ""}
                      onChange={handleChange("cp_phase_start_date")}
                      // data-date={projectDate?.cp_phase_start_date?moment(projectDate?.cp_phase_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                      className="form-control"
                    />
                    {errors.cp_phase_start_date && (
                      <p className="error-text">{errors.cp_phase_start_date}</p>
                    )}
                  </div>
                </div>
               
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Original Prod Support End Date
                    </label>
                    <input
                      name="original_prod_support_end_date"
                      type="date"
                      value={projectDate.original_prod_support_end_date || ""}
                      // data-date={projectDate?.original_prod_support_end_date ?moment(projectDate?.original_prod_support_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      onChange={handleChange("original_prod_support_end_date")}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.original_prod_support_end_date && (
                      <p className="error-text">
                        {errors.original_prod_support_end_date}
                      </p>
                    )}
                  </div>
                </div>
                 <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      C&P Phase End Date
                    </label>
                    <input
                      name="cp_phase_end_date"
                      type="date"
                      value={projectDate.cp_phase_end_date || ""}
                      onChange={handleChange("cp_phase_end_date")}
                      // data-date={projectDate?.cp_phase_end_date ?moment(projectDate?.cp_phase_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                      className="form-control"
                    />
                    {errors.cp_phase_end_date && (
                      <p className="error-text">{errors.cp_phase_end_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Go-Live Date*
                    </label>
                    <input
                      name="go_live_date"
                      type="date"
                      value={projectDate.go_live_date || ""}
                      onChange={handleChange("go_live_date")}
                      placeholder="DD-MMM-YYYY"
                      // data-date={projectDate?.go_live_date?moment(projectDate?.go_live_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.go_live_date && (
                      <p className="error-text">{errors.go_live_date}</p>
                    )}
                  </div>
                </div>
                 <div className="col-md-6"> 
                   <div className="form-group"> 
                    <label htmlFor="project" className="form-label">
                      Requirement Phase Start Date
                    </label>
                    <input
                      name="requirement_phase_start_date"
                      type="date"
                      value={projectDate.requirement_phase_start_date || ""}
                      onChange={handleChange("requirement_phase_start_date")}
                      // data-date={projectDate?.requirement_phase_start_date? moment(projectDate?.requirement_phase_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.requirement_phase_start_date && (
                      <p className="error-text">
                        {errors.requirement_phase_start_date}
                      </p>
                    )}
                   </div> 
                 </div> 
                 <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Hypercare End Date*
                    </label>
                    <input
                      name="hypercare_end_date"
                      type="date"
                      value={projectDate.hypercare_end_date || ""}
                      onChange={handleChange("hypercare_end_date")}
                      // data-date={projectDate?.hypercare_end_date ? moment(projectDate?.hypercare_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                      className="form-control"
                    />
                    {errors.hypercare_end_date && (
                      <p className="error-text">{errors.hypercare_end_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Requirement Phase End Date
                    </label>
                    <input
                      name="requirement_phase_end_date"
                      type="date"
                      value={projectDate.requirement_phase_end_date || ""}
                      onChange={handleChange("requirement_phase_end_date")}
                      // data-date={projectDate?.requirement_phase_end_date ?moment(projectDate?.requirement_phase_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.requirement_phase_end_date && (
                      <p className="error-text">
                        {errors.requirement_phase_end_date}
                      </p>
                    )}
                  </div>
                </div>
                <div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Design Phase Start Date
                    </label>
                    <input
                      name="design_phase_start_date"
                      type="date"
                      value={projectDate.design_phase_start_date || ""}
                      onChange={handleChange("design_phase_start_date")}
                      // data-date={projectDate?.design_phase_start_date ?moment(projectDate?.design_phase_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.design_phase_start_date && (
                      <p className="error-text">
                        {errors.design_phase_start_date}
                      </p>
                    )}
                  </div>
                </div>
               <div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Design Phase End Date
                    </label>
                    <input
                      name="design_phase_end_date"
                      type="date"
                      value={projectDate.design_phase_end_date || ""}
                      onChange={handleChange("design_phase_end_date")}
                      // data-date={projectDate?.design_phase_end_date? moment(projectDate?.design_phase_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                      className="form-control"
                    />
                    {errors.design_phase_end_date && (
                      <p className="error-text">
                        {errors.design_phase_end_date}
                      </p>
                    )}
                  </div>
                </div>
                <div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Build Phase Start Date
                    </label>
                    <input
                      name="build_phase_start_date"
                      type="date"
                      value={projectDate.build_phase_start_date || ""}
                      onChange={handleChange("build_phase_start_date")}
                      // data-date={projectDate?.build_phase_start_date?moment(projectDate?.build_phase_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.build_phase_start_date && (
                      <p className="error-text">{errors.build_phase_start_date}</p>
                    )}
                  </div>
                </div>
                <div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Build Phase End Date
                    </label>
                    <input
                      name="build_phase_end_date"
                      type="date"
                      value={projectDate.build_phase_end_date || ""}
                      onChange={handleChange("build_phase_end_date")}
                      // data-date={projectDate?.build_phase_start_date  ?moment(projectDate?.build_phase_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                      className="form-control"
                    />
                    {errors.build_phase_end_date && (
                      <p className="error-text">
                        {errors.build_phase_end_date}
                      </p>
                    )}
                  </div>
                </div>
                <div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Test Phase Start Date
                    </label>
                    <input
                      name="test_phase_start_date"
                      type="date"
                      value={projectDate.test_phase_start_date || ""}
                      onChange={handleChange("test_phase_start_date")}
                      placeholder="DD-MMM-YYYY"
                      // data-date={projectDate?.test_phase_start_date ?moment(projectDate?.test_phase_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.test_phase_start_date && (
                      <p className="error-text">
                        {errors.test_phase_start_date}
                      </p>
                    )}
                  </div>
                </div>
                <div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      End-to-End Phase Start Date
                    </label>
                    <input
                      name="end_to_end_phase_start_date"
                      type="date"
                      value={projectDate.end_to_end_phase_start_date || ""}
                      onChange={handleChange("end_to_end_phase_start_date")}
                      // data-date={projectDate?.end_to_end_phase_start_date ?moment(projectDate?.end_to_end_phase_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.end_to_end_phase_start_date && (
                      <p className="error-text">
                        {errors.end_to_end_phase_start_date}
                      </p>
                    )}
                  </div>
                </div>
                <div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      End-to-End Phase End Date
                    </label>
                    <input
                      name="end_to_end_phase_end_date"
                      type="date"
                      value={projectDate.end_to_end_phase_end_date || ""}
                      onChange={handleChange("end_to_end_phase_end_date")}
                      // data-date={projectDate?.end_to_end_phase_end_date?moment(projectDate?.end_to_end_phase_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.end_to_end_phase_end_date && (
                      <p className="error-text">
                        {errors.end_to_end_phase_end_date}
                      </p>
                    )}
                  </div>
                </div>
                <div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Parallel Testing Start Date
                    </label>
                    <input
                      name="parallel_testing_start_date"
                      type="date"
                      value={projectDate.parallel_testing_start_date || ""}
                      onChange={handleChange("parallel_testing_start_date")}
                      // data-date={projectDate?.parallel_testing_start_date?moment(projectDate?.parallel_testing_start_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.parallel_testing_start_date && (
                      <p className="error-text">
                        {errors.parallel_testing_start_date}
                      </p>
                    )}
                  </div>
                </div>
                <div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Parallel Testing End Date
                    </label>
                    <input
                      name="parallel_testing_end_date"
                      type="date"
                      value={projectDate.parallel_testing_end_date || ""}
                      onChange={handleChange("parallel_testing_end_date")}
                      // data-date={projectDate?.parallel_testing_end_date ?moment(projectDate?.parallel_testing_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.parallel_testing_end_date && (
                      <p className="error-text">
                        {errors.parallel_testing_end_date}
                      </p>
                    )}
                  </div>
                </div>
                <div></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="project" className="form-label">
                      Test Phase End Date
                    </label>
                    <input
                      name="test_phase_end_date"
                      type="date"
                      value={projectDate.test_phase_end_date || ""}
                      onChange={handleChange("test_phase_end_date")}
                      // data-date={projectDate?.test_phase_end_date ?moment(projectDate?.test_phase_end_date)?.format("DD-MMM-YYYY"):"DD-MMM-YYYY"}
                      placeholder="DD-MMM-YYYY"
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                    />
                    {errors.test_phase_end_date && (
                      <p className="error-text">{errors.test_phase_end_date}</p>
                    )}
                  </div>
                </div>
                <div></div>
              <div className="col-md-6">
                  <div className="form-group">
                    <div className="btn-block">
               {ProfileType?.ADMIN === getProfileType &&       <button type="submit" className="thame-btn w-100">
                        {actionType === "create" ? "Submit" : "Update"}
                      </button>}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CustomModal
        title="Project Dates"
        onOpen={errorPopup}
        content={messages}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Project Dates"
        onOpen={successPopup}
        content={"Sub Project Dates data saved!"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
       <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </div>
  );

  //   })
};
export default SubProjectDates;
