import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./CustomerContactsForm.css";

import {
  customerCreateContact,
  getCustomerUserByName,
  getCustomerDetails,
} from "../../../store/actions/Customer";
import { useForm } from "../../../helpers/validations/useForm";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RegisterForm from "../../Register/register";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { errorMessages, REPLACE_REGEX } from "../../../constants/common";
import { ProfileType } from "../../../utility/commonTypes";


interface CustomerContactInterface {
  id: any;
  ResourceName: any;
  emailid: any;
  company_customer_id: any;
  user_id: any;
  start_date: any;
  end_date: any;
  role: any;
  team: any;
  notes: any;
  active: any;
  allow_logon: any;
  allow_notifications: any;
  actionType: any;
}

const CustomerContactsForm = ({
  openModalPop,
  setOpenModalPop,
  row,
  onSuccess,
  handleClose,
}: any) => {
  const dispatch = useDispatch();
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [resourceName, setResourceName] = useState("");
  const location = useLocation();
  const params: any = useParams();
  const [emailId, setEmailId] = useState("");
  const stateData: any = params;
  const [checked, setChecked] = useState(false);
  const [actionType, setActionType] = useState("create");
  const [suggestions, setSuggestions] = useState([]);
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState<any>(false);
  const [message, setMessage] = useState([]);
  const [open, setOpen] = useState(false);
  const [companyCustomerDetail, setCompanyCustomerDetail] = useState({
    id: "",
    companyName: "",
  });
  const [openCustomerRegisterPopUp, setOpenCustomerRegisterPopUp] = useState(
    false
  );

  const handleEnter = (e: any) => {
    e.key === "Enter" && e.preventDefault();
  };
  const [checkedAllowLogOn, setCheckedAllowLogOn] = useState(false);
  const [checkedAllowNotification, setCheckedAllowNotification] = useState(
    false
  );
  const handleActiveCheckBox = (e: any) => {
    setChecked(e.target.checked);
  };
  const handleLogOnCheckBox = (e: any) => {
    setCheckedAllowLogOn(e.target.checked);
  };
  const handleNotificationCheckBox = (e: any) => {
    setCheckedAllowNotification(e.target.checked);
  };
  
  const {
    handleSubmit,
    handleChange,
    data: customerContacts,
    errors,
    setData,
  } = useForm<CustomerContactInterface>({
    validations: {
      ResourceName: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
        custom: {
          isValid: (value) => {
            console.log("value is", value, resourceName);
            if (
              (value !== undefined &&
                value != null &&
                value !== "" &&
                resourceName === "") ||
              value !== resourceName
            ) {
              return false;
            }
            return true;
          },
          message: "Please select valid Resource Name",
        },
      },
      start_date:{
        required:{
          value:true,
          message:errorMessages?.required()
        }
      },
      end_date:{
        required:{
          value:true,
          message:errorMessages?.required()
        }
      },
      role:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 100,
          message: errorMessages?.maxlength("Role", 100),
        },
      },
      team:{
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.pattern()
        },
        maxlength: {
          value: 100,
          message: errorMessages?.maxlength("Team",100)
        },
      },
      notes:{
        pattern:{
        value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
        message:errorMessages?.pattern(),
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Notes",5000),
        },
      },
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      setLoader(true);
      dispatch(
        customerCreateContact(
          {
            payload: {
              id: customerContacts?.id,
              ResourceName: customerContacts?.ResourceName,
              emailid: customerContacts?.emailid,
              company_customer_id: stateData?.customerId,
              start_date: customerContacts?.start_date,
              end_date: customerContacts?.end_date,
              user_id: customerContacts?.user_id,
              role: customerContacts?.role,
              team: customerContacts?.team,
              notes: customerContacts?.notes,
              active: checked,
              allow_logon: checkedAllowLogOn,
              allow_notifications: checkedAllowNotification,

              actionType: actionType,
            },
          },
          onCustomerContactsCallback
        )
      );
    },
  });
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const getCustomerDetail = (customerId: any) => {
    if (stateData?.customerId) {
      setLoader(true);
      dispatch(
        getCustomerDetails({ id: customerId }, function (res: any) {
          setCompanyCustomerDetail({
            id: stateData?.customerId,
            companyName: res.company_name,
          });
          setLoader(false);
        })
      );
    }
  };
  useEffect(() => {
    stateData?.customerId && getCustomerDetail(stateData?.customerId);
    if (row && row?.User?.emailid) {
      row?.User?.emailid && setActionType("update");
      console.log("row is", row);
      setResourceName(row?.User?.name);
      setEmailId(row?.User?.emailid);
      setData({
        id: row?.id,
        ResourceName: row?.User?.name,
        emailid: row?.User?.emailid,
        company_customer_id: row?.User?.company_customer_id,
        start_date: row?.start_date.split("T")[0],
        end_date: row?.end_date.split("T")[0],
        role: row?.role,
        team: row?.team,
        notes: row?.notes,
        active: row?.User?.active,
        allow_logon: row?.User?.allow_logon,
        allow_notifications: row?.User?.allow_notifications,
      });
      setChecked(row?.User?.active);
      setCheckedAllowLogOn(row?.User?.allow_logon);
      setCheckedAllowNotification(row?.User?.allow_notifications);
    } else {
      setActionType("create");
      setData({
        id: "",
        ResourceName: "",
        emailid: "",
        company_customer_id: "",
        start_date: "",
        end_date: "",
        role: "",
        team: "",
        notes: "",
        active: false,
        allow_logon: false,
        allow_notifications: false,
      });
    }
  }, [setData, row]);

  const handleKeyUpEvent = (event: any) => {
    dispatch(
      getCustomerUserByName(
        {
          searchWord: event?.target?.value,
          customerUser: 1,
          companyId: stateData?.customerId,
        },
        function (res: any) {
          if (res === "Match not found") {
            res = "";
          }

          setSuggestions({
            ...res,
          });
        }
      )
    );
  };

  const onCustomerContactsCallback = (data: any, error: any) => {
    if (data === false) {
      setMessage(error);
      setErrorPopup(true);
    } else {
      onSuccess();
      setOpenModalPop(false);
    }

    setLoader(false);
  };
  const handleClickOpen = () => {
    setOpenCustomerRegisterPopUp(true);
  };
  useEffect(() => {
    openModalPop && setOpenModalPop(true);
  }, [openModalPop]);
  let suggestRef=useRef()
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!suggestRef?.current?.contains(event.target)) {
        setShowSuggestion(false);
      }
    });
  }, []);
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  return (
    <>
      <CustomModal
        title="Select Customer Contact"
        onOpen={openModalPop}
        height="95%"
        width="md"
        customWidth="750px"
        backgroundColor="#E1E1E1"
        content={
          <div className="modal-body mt-4">
            <form onSubmit={handleSubmit}>
              {/* <div className="d-flex">
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="checked"
                    onChange={handleActiveCheckBox}
                    checked={checked}
                    value={customerContacts?.active}
                    className="form-check-input mx-2"
                    onKeyPress={handleEnter}
                  />
                  <label
                    htmlFor="checked"
                    className="form-check-label mt-1"
                    style={{ width: "80px" }}
                  >
                    Active
                  </label>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    onChange={handleLogOnCheckBox}
                    checked={checkedAllowLogOn}
                    value={customerContacts?.allow_logon}
                    className="form-check-input mx-2"
                    onKeyPress={handleEnter}
                  />
                  <label
                    className="form-check-label mt-1"
                    style={{ width: "120px" }}
                  >
                    Allow Logon
                  </label>
                </div>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="checked"
                    onChange={handleNotificationCheckBox}
                    checked={checkedAllowNotification}
                    value={customerContacts?.allow_notifications}
                    className="form-check-input mx-2"
                    onKeyPress={handleEnter}
                  />
                  <label
                    htmlFor="checked"
                    className="form-check-label mt-1"
                    style={{ width: "150px" }}
                  >
                    Allow Notification
                  </label>
                </div>
              </div> */}
              <div className="row gx-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Resource Name*  <AddCircleOutlineIcon onClick={handleClickOpen} />
</label>

                    <input
                      name="ResourceName"
                      id="ResourceName"
                      type="text"
                      value={customerContacts?.ResourceName}
                      onChange={handleChange("ResourceName", (val) => {
                        setShowSuggestion(true);
                        return val;
                      })}
                      onKeyUp={handleKeyUpEvent}
                      onKeyPress={handleEnter}
                      placeholder="Resource Name"
                      className="form-control"
                    />

                    {errors?.ResourceName && (
                      <p className="error-text">{errors?.ResourceName}</p>
                    )}
                    {showSuggestion && (
                      <ul
                        className="list-group"
                        ref={suggestRef}
                        style={{ position: "absolute", zIndex: 1 , overflow:"auto",
                        right: 0,
                        left: 0,
                        width: "100%",
                        height: 130,
                        top:92
                      }}                      >
                        {" "}
                        {Object.entries(suggestions)?.map(
                          ([suggest, i]: any) => {
                            return (
                              <li
                                className="list-group-item"
                                onClick={(e) => {
                                  setShowSuggestion(false);
                                  setResourceName(i?.ResourceName);
                                  setEmailId(i?.emailid);
                                  setData({
                                    ...customerContacts,
                                    emailid: i?.emailid,
                                    user_id: i?.id,
                                    company_customer_id: i?.company_customer_id,
                                    ResourceName: i?.ResourceName,
                                  });
                                }}
                              >
                                {i?.ResourceName}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email ID</label>
                    <input
                      name="emailid"
                      id="emailid"
                      type="text"
                      value={customerContacts?.emailid}
                      onChange={handleChange("emailid")}
                      placeholder="Email Id"
                      className="form-control"
                      onKeyPress={handleEnter}
                    />
                    {errors?.emailid && (
                      <p className="error-text">{errors?.emailid}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Company/Customer</label>
                    <select
                      name="company_customer_id"
                      id="company_customer_id"
                      value={customerContacts?.company_customer_id}
                      onChange={handleChange("company_customer_id")}
                      className="form-control"
                      disabled
                    >
                      <option value={companyCustomerDetail?.id} selected>
                        {companyCustomerDetail?.companyName}
                      </option>
                    </select>
                    {errors.company_customer_id && (
                      <p className="error-text">{errors.company_customer_id}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Start Date*</label>
                    <input
                      type="date"
                      value={customerContacts?.start_date}
                      onChange={handleChange("start_date")}
                      className="form-control"
                      onKeyPress={handleEnter}
                    />
                     {errors.start_date && (
                      <p className="error-text">{errors.start_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">End Date*</label>
                    <input
                      type="date"
                      value={customerContacts?.end_date}
                      onChange={handleChange("end_date")}
                      className="form-control"
                      onKeyPress={handleEnter}
                    />
                     {errors.end_date && (
                      <p className="error-text">{errors.end_date}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Role</label>
                    <input
                      name="role"
                      id="role"
                      type="text"
                      value={customerContacts?.role}
                      onChange={handleChange("role")}
                      placeholder="Role"
                      className="form-control"
                      onKeyPress={handleEnter}
                    />
                    {errors?.role && (
                      <p className="error-text">{errors?.role}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Team</label>
                    <input
                      name="team"
                      id="team"
                      type="text"
                      value={customerContacts?.team}
                      onChange={handleChange("team")}
                      placeholder="Team"
                      className="form-control"
                      onKeyPress={handleEnter}
                    />
                    {errors?.team && (
                      <p className="error-text">{errors?.team}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12 marginTopCls">
                  <div className="form-group">
                    <label className="form-label">Notes </label>
                    <textarea
                      name="notes"
                      value={customerContacts?.notes}
                      onChange={handleChange("notes")}
                      placeholder="Notes"
                      className="form-textarea"
                    ></textarea>
                    {errors.notes && (
                      <p className="error-text">{errors.notes}</p>
                    )}
                  </div>
                </div>
                <br />
                <br />
          {ProfileType?.ADMIN === getProfileType &&      <div className="col-md-12">
                  {actionType === "create" ? (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                      onClick={() => {
                        setLoader(false);
                      }}
                    >
                      ADD
                    </button>
                  ) : (
                    <button
                      className="btn add_button btn-primary mx-0 w-auto"
                      id="create"
                    >
                      UPDATE
                    </button>
                  )}{" "}
                  <button
                    type="button"
                    onClick={() => handleClose()}
                    className="btn add_button btn-primary mx-0 w-auto mx-3 cancelBtnCls"
                  >
                    CANCEL
                  </button>
                </div>}
              </div>
            </form>
          </div>
        }
        OnClickCloseIcon={handleClose}
      />
      <CustomModal
        title="Customer Contacts"
        onOpen={errorPopup}
        content={message}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Customer Contact"
        onOpen={successPopup}
        content={"Customer Contact Added/Updated Successfully"}
        modalType={"success"}
        width={"sm"}
        OnClickFirstBtn={onSuccess}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
        <CustomModal
        title="Create Customer"
        height="80%"
        width="md"
        onOpen={openCustomerRegisterPopUp}
        customWidth="750px"
        backgroundColor="#E1E1E1"
        content={<RegisterForm companyId={stateData?.customerId} userType={"Customer"} openCustomerRegisterPopUp={openCustomerRegisterPopUp} setOpenCustomerRegisterPopUp={setOpenCustomerRegisterPopUp}/>}
        OnClickCloseIcon={() => {
          setOpenCustomerRegisterPopUp(false);
        }}
      />
       <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
          Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
    </>
  );
};

export default CustomerContactsForm;
