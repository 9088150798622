const columns: any = () => {
  return [
    {
      id: 'Action',
      label: 'Action',
      minWidth: 90,
      align: "center",
      InputType: "ACTION"
    },
    {
      id: 'type',
      label: 'Type',
      minWidth: 110,
      align: "left",
      InputType: "SELECT"
    },
    {
      id: 'field_order',
      label: 'Sr. no',
      minWidth: 60,
      align: "left",
      InputType: "INPUT"
    },
    {
      id: 'Target_System_Field_Name',
      label: 'Target System Field Name',
      minWidth: 110,
      align: "left",
      InputType: "INPUT"
    },
    // {
    //   id: 'Description',
    //   label: 'Business Description',
    //   minWidth: 110,
    //   align: "left",
    //   InputType: "INPUT"

    // },
    // {
    //   id: 'Comments',
    //   label: 'Comments',
    //   minWidth: 70,
    //   align: "left",
    //   InputType: "INPUT"
    // },
    // {
    //   id: 'Transformation_Rules',
    //   label: 'Transformation rules',
    //   minWidth: 110,
    //   align: "left",
    //   InputType: "INPUT"
    // },
    {
      id: 'Business_Description',
      label: 'Business Description',
      minWidth: 110,
      align: "left",
      InputType: "INPUT"
    },
    {
      id: 'Workday_Field_Name',
      label: 'Workday Field Name',
      minWidth: 110,
      align: "left",
      InputType: "INPUT"
    },
    {
      id: 'Required',
      label: 'Required?',
      minWidth: 70,
      align: "left",
      InputType: "CHECKBOX"
    },
    {
      id: 'Max_Length',
      label: 'Max Length',
      minWidth: 110,
      align: "left",
      InputType: "INPUT"
    },
    // {
    //   id: 'Format',
    //   label: 'Format',
    //   minWidth: 70,
    //   align: "left",
    //   InputType: "INPUT"
    // },
    {
      id: 'ValuesorField_Logic',
      label: 'Values/Field Logic ',
      minWidth: 110,
      align: "left",
      InputType: "INPUT"
    },
   
    {
      id: 'Mapping',
      label: 'Mapping',
      minWidth: 70,
      align: "center",
      InputType: "MAPPING"

    },
  ]
};

export default columns;