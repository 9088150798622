import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { globalStore, persister } from "./store";
import { globalStoreContext } from "./store/context";
import reportWebVitals from "./reportWebVitals";
import { LayoutProvider } from "./store/context/LayoutContext";
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/css/bootstrap-theme.css';
import { msalConfig } from "./components/Authentication/authProvider";
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
// import 'bootstrap/dist/css/bootstrap-theme.css';
export const msalInstance = new PublicClientApplication(msalConfig);
// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <LayoutProvider>
    <Provider store={globalStore}>
      <App pca={msalInstance}/>
    </Provider>
  </LayoutProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
