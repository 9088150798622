import React, { useState, useEffect, FC } from "react";
import "./CustomerForm.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../helpers/validations/useForm";
import { signupCustomer } from "../../store/actions/Customer";
import { getCustomerDetails } from "../../store/actions/Customer";
import { DELOITTE_ENTITY, errorMessages, FILE_BASE_URL, REGION, REPLACE_REGEX } from "../../constants/common";
import CustomModal from "../../components/CustomModalPop";
import setLoader from "../../components/CustomLoader/loaderState";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import PDF from "../Requirement/Print/images/pdf.svg"
import { ProfileType } from "../../utility/commonTypes";
interface Customer {
  id: any;
  customer_id: any;
  active: any;
  company_name: any;
  industry: any;
  about: any;
  headquarter: any;
  region: any;
  legacy_hr_system: any;
  total_workers: any;
  notes: any;
  website: any;
  managed_by_deloitte_entity: any;
  company_logo: any;
  actionType: any;
  checked: any;
}

const CustomerForm: FC = (props) => {
  const { customerDetail } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [actionType, setActionType] = useState("create");
  const location = useLocation();
  const params: any = useParams();
  //const stateData: any = location.state;
  const stateData: any = params;
  const [checkbox, setCheckBox] = useState(false);
  const [message, setMessage] = useState("");
  console.log({ location, params });
  const [file, setFile] = useState<any>({});
  const [customerLogo, setCustomerLogo] = useState<any>({});
  const [companyLogo, setCompanyLogo] = useState({ logo: "" });
  const [uploaded, setUploaded] = useState(false);
  const selector = useSelector((store: any) => {
    return store?.customerDetail?.data;
  });

  const handleCheckBox = (e: any) => {
    setCheckBox(e.target.checked);
  };
  const handleFile = (e: any) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setUploaded(true);
    setCustomerLogo(e.target.files);
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const {
    handleSubmit,
    handleChange,
    handleFileUpload,
    data: customer,
    errors,
    setData,
  } = useForm<Customer>({
    validations: {
      customer_id: {
        required: {
          value: true,
          message: errorMessages?.required()
        },
        pattern: {
          value: REPLACE_REGEX?.START_WITH_ALPHABETS_NUMBERS,
          message:errorMessages?.pattern()
            // "Please Enter Valid ID combination of Alphanumeric and special character allowed(-,_,#) ex: INT001, INT-001, INT#001",
        
        },
        minlength: {
          value: 1,
          message: errorMessages?.minlength("Customer id",1)
        },
        maxlength: {
          value: 20,
          message: errorMessages?.maxlength("Customer id",20)
        },
      },
      company_name: {
        required: {
          value: true,
          message: errorMessages?.required(),
        },
        pattern:{
          value:REPLACE_REGEX?.COMPANY_NAME_OR_TASK_ID,
          message:errorMessages?.patternStartsWithAlphabets()
        },
        minlength: {
          value: 1,
          message: errorMessages?.minlength("Customer Name",1)
        },
        maxlength: {
          value: 50,
          message: errorMessages?.maxlength("Customer Name",50),
        },
      },
      industry: {
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.patternStartsWithAlphabets()
        },
        maxlength: {
          value: 50,
          message: errorMessages?.maxlength("Industry",50)
        },
      },
      legacy_hr_system: {
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITHOUT_SPECIAL_CHARS,
          message:errorMessages?.patternWithOutSpecialChars()
        },
        maxlength: {
          value: 50,
          message: errorMessages?.maxlength("Legacy HR System",50)
        },
      },
      headquarter: {
        pattern:{
          value:REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.patternStartsWithAlphabets()
        },
        maxlength: {
          value: 50,
          message: errorMessages?.maxlength("Headquarter", 50)
        },
      },
     
      notes: {
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.patternStartsWithAlphabets()
        },
        maxlength: {
          value: 5000,
          message: errorMessages?.maxlength("Notes",5000),
        },
      },
      total_workers: {
        pattern: {
          value: REPLACE_REGEX?.ONLY_NUMBER,
          message:errorMessages?.patternWithOnlyNumbers(),
        },
        maxlength: {
          value: 7,
          message: errorMessages?.maxlength("Total workers",7),
        },
      },
        about: {
        pattern: {
          value: REPLACE_REGEX?.ALPHANUMERIC_WITH_SPECIAL_CHARS,
          message:errorMessages?.patternStartsWithAlphabets()
        },
          maxlength: {
            value: 5000,
            message: errorMessages?.maxlength("About",5000)
          },
      },
      company_logo: {
        custom: {
          isValid: (value) => {
            console.log("File Value", value);
            if (customerLogo?.name !== undefined && customerLogo != null) {
              if (customerLogo[0]?.name?.match(/\.(jpg|jpeg|png)$/)) {
                return true;
              }
              return false;
            }
            return true;
          },
          message: "Company Logo Should be JPG/JPEG/PNG/GIF",
        },
      },
      website: {
        pattern: {
          value:
            "^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$",
          message:
            "Entered value is not Valid.Please provide valid website Url",
        },
        maxlength: {
          value: 100,
          message: errorMessages?.maxlength("Website",100),
        },
      },
    },
    onError: (errors: any) => {
      console.log({ errors }, Object.keys(errors).length);
      const isError = Object.keys(errors).length;
      if (isError) {
        setOpen(true);
      }
    },
    onSubmit: () => {
      setLoader(true);
      let jsonFormat = {
        id: customer.id,
        active: checkbox,
        customer_id: customer.customer_id,
        company_name: customer.company_name,
        industry: customer.industry,
        about: customer.about,
        headquarter: customer.headquarter,
        region: customer.region,
        legacy_hr_system: customer.legacy_hr_system,
        total_workers: customer.total_workers || null,
        notes: customer.notes,
        website: customer.website || null,
        company_logo: customerLogo[0],
        managed_by_deloitte_entity: customer.managed_by_deloitte_entity,
        actionType: actionType,
      };
      console.log(
        "logo",
        customer?.company_logo?.size,
        customer?.company_logo?.name
      );
      if (
        customerLogo[0]?.size === undefined &&
        customerLogo[0]?.name === undefined
      ) {
        delete jsonFormat.company_logo;
      }
      dispatch(signupCustomer(jsonFormat, onSignUpCustomerCallback));
      if (
        customer?.company_logo?.size === undefined &&
        customer?.company_logo?.name === undefined
      ) {
        delete jsonFormat.company_logo;
      }
      dispatch(signupCustomer(jsonFormat, onSignUpCustomerCallback));
    },
  });

  useEffect(() => {
    if (stateData?.customerId) {
      setLoader(true);
      dispatch(
        getCustomerDetails({ id: stateData?.customerId }, function (res: any) {
          res?.id && setActionType("update");
          console.log("res is", res?.company_logo);
          let imgDetail = [{ company_logo: res?.company_logo }];
          console.log("details", imgDetail);
          setCompanyLogo({ logo: res?.company_logo });
          // res?.company_logo && setFile(URL.createObjectURL(res?.company_logo))
          res?.company_logo && setCustomerLogo([{ name: res?.company_logo }]);

          customerDetail(res);
          setData({
            ...res,
            active: res.active,
            company_name: res.company_name,
            industry: res.industry,
            about: res.about,
            headquarter: res.headquarter,
            region: res.region,
            legacy_hr_system: res.legacy_hr_system,
            total_workers: res.total_workers || "",
            notes: res.notes,
            website: res.website || "",
            managed_by_deloitte_entity: res.managed_by_deloitte_entity,
          });
          setCheckBox(res?.active);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        })
      );
    }
  }, [setData]);

  const onSignUpCustomerCallback = (data: any, error: any) => {
    console.log("data", data);
    if (data === false) {
      setMessage(error);
      setErrorPopup(true);
    } else {
      setMessage("Customer Updated Successfully");
      setSuccessPopup(true);
      setTimeout(() => {
        navigate(`/customer/${data?.id}/${data?.company_name}`, {
          state: {
            customerId: data?.id,
            companyId: data?.company_name,
          },
          replace: true,
        });
        window.location.reload();

        // else{
        //   navigate(
        //     `/project/${data?.id}/${data?.company_customer_id}`,
        //     {
        //       state: {
        //         projectId: data?.id,
        //         customerId: data?.company_customer_id,
        //       },
        //       replace: true,
        //     }
        //   );
        //   window.location.reload();
        // }
      }, 2000);
    }
    setLoader(false);
  };
  const handleEnter = (e: any) => {
    e.key === "Enter" && e.preventDefault();
  };
  console.log("file is", customerLogo[0]?.name);
  let filePath = customerLogo[0]?.name;
  // let fileName=filePath.substring(filePath.lastIndexOf('/')+1)
  // console.log("file name is",fileName)
  const getProfileType = useSelector((store: any) => {
    return store.auth?.globalAuthData?.usertype;
  });
  return (
    <section className="main-contant inner_page">
      <div className="container-fluid ">
        <div className="row">
          <div className="col-xl-10 col-xxl-7">
            <div className="frame_outer">
              <div className="col-md-12 pull-left  mb-4 vendor_contact">
                Main
              </div>
              <form onSubmit={handleSubmit}>
                {/* <div className="d-flex">
                     
                      <input
                        type="checkbox"
                        name="checked"
                        onChange={handleCheckBox}
                        value={customer?.active}
                        defaultChecked={customer?.active}
                        className="form-check-input mx-2"
                         onKeyPress={handleEnter} */}

                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="checkbox"
                    onChange={handleCheckBox}
                    value={customer?.active}
                    checked={checkbox}
                    className="form-check-input mx-2"
                    onKeyPress={handleEnter}
                  />
                  <label
                    htmlFor="checked"
                    className="form-check-label mt-1"
                    style={{ width: "80px" }}
                  >
                    Active
                  </label>
                </div>

                <div className="row gx-5 mt-5">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label customername">
                        Customer ID*
                      </label>
                      <div>
                        <input
                          name="customer_id"
                          id="customer_id"
                          type="text"
                          value={customer?.customer_id}
                          onChange={handleChange("customer_id")}
                          placeholder="Customer ID"
                          className="form-control"
                          onKeyPress={handleEnter}
                        />
                        {errors.customer_id && (
                          <p className="error-text">{errors.customer_id}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label customername">
                        Customer Name*
                      </label>
                      <div>
                        <input
                          name="company_name"
                          id="company_name"
                          type="text"
                          value={customer?.company_name}
                          onChange={handleChange("company_name")}
                          placeholder="Customer Name"
                          className="form-control"
                          onKeyPress={handleEnter}
                        />
                        {errors.company_name && (
                          <p className="error-text">{errors.company_name}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Industry
                      </label>
                      <div>
                        <input
                          name="industry"
                          id="industry"
                          value={customer?.industry}
                          onChange={handleChange("industry")}
                          className="form-control"
                        />
                        {errors.industry && (
                          <p className="error-text">{errors.industry}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Legacy HR System
                      </label>
                      <input
                        name="legacy_hr_system"
                        type="text"
                        value={customer.legacy_hr_system}
                        onChange={handleChange("legacy_hr_system")}
                        placeholder="Leagcy HR System"
                        className="form-control"
                      />
                      {errors.legacy_hr_system && (
                        <p className="error-text">{errors.legacy_hr_system}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Headquarter
                      </label>
                      <input
                        name="headquarter"
                        type="text"
                        value={customer?.headquarter}
                        onChange={handleChange("headquarter")}
                        placeholder="Headquarter"
                        className="form-control"
                      />
                      {errors.headquarter && (
                        <p className="error-text">{errors.headquarter}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        Region
                      </label>
                      <select
                        name="region"
                        id="region"
                        value={customer?.region}
                        onChange={handleChange("region")}
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value="" selected>
                          Select Region
                        </option>
                        {REGION.map((region) => (
                          <option value={region.key}>{region.label}</option>
                        ))}
                      </select>
                      {errors.region && (
                        <p className="error-text">{errors.region}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-8">
                          <label htmlFor="name" className="form-label">
                            Total Workers
                          </label>
                        </div>
                      </div>
                      <input
                        name="total_workers"
                        type="text"
                        value={customer.total_workers}
                        onChange={handleChange("total_workers")}
                        className="form-control"
                      />
                      {errors.total_workers && (
                        <p className="error-text">{errors.total_workers}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        About the Customer
                      </label>
                      <div>
                        <textarea
                          name="about"
                          id="about"
                          value={customer.about || ""}
                          onChange={handleChange("about")}
                          placeholder="About the Company"
                          className="form-textarea"
                        ></textarea>
                        {errors.about && (
                          <p className="error-text">{errors.about}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-8">
                          <label htmlFor="name" className="form-label">
                            Notes
                          </label>
                        </div>
                      </div>
                      <textarea
                        name="notes"
                        id="notes"
                        rows={5} 
                        cols={5}
                        value={customer?.notes}
                        onChange={handleChange("notes")}
                        className="form-textarea"
                        placeholder="Notes"
                      ></textarea>
                      {errors.notes && (
                        <p className="error-text">{errors.notes}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Website
                      </label>
                      <input
                        name="website"
                        type="text"
                        value={customer.website}
                        onChange={handleChange("website")}
                        placeholder="Website"
                        className="form-control"
                      />
                      {errors.website && (
                        <p className="error-text">{errors.website}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Customer Logo
                      </label>
                      <input
                        name="company_logo"
                        id="company_logo"
                        type="file"
                        onChange={handleFile}
                        className="form-control"
                      />
                      {uploaded && (
                        <img
                          src={file}
                          alt="preview"
                          height="500"
                          width="500"
                          className="img-fluid"
                        />
                      )}

                      {!uploaded  && companyLogo?.logo!==null && companyLogo?.logo!== "" &&
                        <img
                         src={`data:image/png;base64,${companyLogo?.logo}`}
                         alt="upload preview"
                          width="500"
                          height="500"
                          className="img-fluid"
                        />
} 
                      {errors.company_logo && (
                        <p className="error-text">{errors.company_logo}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="project" className="form-label">
                        Managed By Deloitte Entity
                      </label>
                      <select
                        name="managed_by_deloitte_entity"
                        value={customer?.managed_by_deloitte_entity}
                        onChange={handleChange("managed_by_deloitte_entity")}
                        placeholder="Managed By Deloitte Entity"
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value="">--Select Entity--</option>
                        {DELOITTE_ENTITY.map((entity) => (
                          <option value={entity.key}>{entity.label}</option>
                        ))}
                      </select>
                      {errors.managed_by_deloitte_entity && (
                        <p className="error-text">
                          {errors.managed_by_deloitte_entity}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="btn-block">
                  {ProfileType?.ADMIN === getProfileType &&      <button type="submit" className="thame-btn w-100">
                          {actionType === "create" ? "Submit" : "Update"}
                        </button>}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <CustomModal
          title="Customer Profile"
          onOpen={errorPopup}
          content={message}
          modalType={"error"}
          width={"xs"}
          OnClickCloseIcon={() => {
            setErrorPopup(false);
          }}
        />
        <CustomModal
          title="Customer Profile"
          onOpen={successPopup}
          content={"Customer data saved!"}
          modalType={"success"}
          width={"xs"}
          OnClickCloseIcon={() => {
            setSuccessPopup(false);
          }}
        />

        <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              Error on page, please validate!
            </Alert>
          </Snackbar>
        </Stack>
      </div>
    </section>
  );
};

export default CustomerForm;
