import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setLoader from "../../../components/CustomLoader/loaderState";
import CustomModal from "../../../components/CustomModalPop";
import { DELIVERY_TRANSPORT, TECHNICAL } from "../../../constants/common";
import { useForm } from "../../../helpers/validations/useForm";
import {
  getRequirementTransport,
  updateRequirementTransport,
} from "../../../store/actions/Requirement";
interface RequirementTransport {
  id: any;
  field_data: any;
  requirement_id: any;
  Transport_Details_available_with_the_required_infrastructure: any;
  Use_Workday_Temp_File_Functionality: any;
  Delivery_Transport_Production: any;
  AddressorURL_Production: any;
  Directory_Production: any;
  Username_Production: any;
  Authentication_Production: any;
  Delivery_Transport_Test: any;
  AddressorURL_Test: any;
  Directory_Test: any;
  Username_Test: any;
  Authentication_Test: any;
  Document_Retention_Period: any;
  Encryption_Required: any;
  Delete_file_after_retrieval_applicable_for_inbound_integration: any;
  Notes: any;
}
const Transport = () => {
  const [successPopup, setSuccessPopup] = useState(false);
  const location = useLocation();
  const params: any = useParams();
  const stateData: any = params;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, handleChange, data: transport, setData,errors } = useForm<
    RequirementTransport
  >({
    validations:{
      AddressorURL_Production:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Entered value should be less than 50 character length",
        },
      },
      Directory_Production:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Entered value should be less than 50 character length",
        },
      },
      Username_Production:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Entered value should be less than 50 character length",
        },
      },
      Authentication_Production:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Entered value should be less than 50 character length",
        },
      },
      AddressorURL_Test:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Entered value should be less than 50 character length",
        },
      },
      Directory_Test:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Entered value should be less than 50 character length",
        },
      },
      Username_Test:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Entered value should be less than 50 character length",
        },
      },
      Authentication_Test:{
        pattern: {
          value: "(^w|_d)*[A-Za-z].*$",
          message:
            "Please Enter combination of Alphanumeric and special character allowed",
        },
        maxlength: {
          value: 50,
          message: "Entered value should be less than 50 character length",
        },
      },
      Document_Retention_Period:{
        pattern: {
          value: "[0-9]+$",
          message:
            "Entered value is not valid.Only Numeric values are allowed",
        },
        maxlength: {
          value: 3,
          message: "Entered value should be less than 3 numbers",
        },
      },
      Notes:{
        pattern: {
          value: "(^w|_d)*[A-Za-z0-9].*$",
          message:
            "Please enter combination of Alphanumeric and special characters are allowed",
        },
        maxlength: {
          value: 5000,
          message: "Entered value should be less than 5000 character length",
        },
      }
    },
    onSubmit: () => {
      // setLoader(true);
      let res: any = {};      
      for (const i in transport) {
        const transportValue = transport[i];
        res[i] = {
          field_desc: transportValue !== undefined ? transportValue : "",
          test_cases: 23,
        };
      }
      dispatch(
        updateRequirementTransport(
          {
            payload: {
              id: transport?.id,
              requirement_id: stateData?.requirementId,
              field_data: res,
              test_cases: 23,
            },
          },

          onUpdateTransport
        )
      );
    },
  });

  const [errorPopup, setErrorPopup] = useState(false);
  const currentTabData=(requirementId: any)=>{
    dispatch(
      getRequirementTransport({ id: requirementId }, function (
        result: any
      ) {
        setData({
          ...result,
          Transport_Details_available_with_the_required_infrastructure:
            result?.field_data
              ?.Transport_Details_available_with_the_required_infrastructure
              ?.field_desc,
          Use_Workday_Temp_File_Functionality:
            result?.field_data?.Use_Workday_Temp_File_Functionality?.field_desc,
          Delivery_Transport_Production:
            result?.field_data?.Delivery_Transport_Production?.field_desc,
          AddressorURL_Production:
            result?.field_data?.AddressorURL_Production?.field_desc,
          Directory_Production:
            result?.field_data?.Directory_Production?.field_desc,
          Username_Production:
            result?.field_data?.Username_Production?.field_desc,
          Authentication_Production:
            result?.field_data?.Authentication_Production?.field_desc,
          Delivery_Transport_Test:
            result?.field_data?.Delivery_Transport_Test?.field_desc,
          AddressorURL_Test: result?.field_data?.AddressorURL_Test?.field_desc,
          Directory_Test: result?.field_data?.Directory_Test?.field_desc,
          Username_Test: result?.field_data?.Username_Test?.field_desc,
          Authentication_Test:
            result?.field_data?.Authentication_Test?.field_desc,
          Document_Retention_Period:
            result?.field_data?.Document_Retention_Period?.field_desc,
          Encryption_Required:
            result?.field_data?.Encryption_Required?.field_desc,
          Delete_file_after_retrieval_applicable_for_inbound_integration:
            result?.field_data
              ?.Delete_file_after_retrieval_applicable_for_inbound_integration
              ?.field_desc,
          Notes: result?.field_data?.Notes?.field_desc,
        });
      })
    );
  }
  const onUpdateTransport = (data: any) => {
    //    close the popup and reload the datagrid
    setLoader(false);
    if (data == false) {
      setErrorPopup(true);
    } else {
      setSuccessPopup(true);
      setTimeout(() => {
       setSuccessPopup(false)
       window.scrollTo(0,0)
       currentTabData(stateData?.requirementId)
      }, 2000);
    }
  };
  useEffect(() => {
   currentTabData(stateData?.requirementId)
  }, [setData]);
  return (
    <div className="container-fluid ">
      <div className="col-xl-10 col-xxl-7">
        <div className="frame_outer">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="pull-left mt-2 mb-3 vendor_contact">
                  Transport Requirement
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="mt-2 pull-left vendor_contact">Test case</div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Transport Details available with the required
                    infrastructure?{" "}
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={
                      transport?.Transport_Details_available_with_the_required_infrastructure
                    }
                    onChange={handleChange(
                      "Transport_Details_available_with_the_required_infrastructure"
                    )}
                  >
                    <option value="">Select</option>
                    {TECHNICAL.map((transport) => (
                      <option value={transport.key}>{transport.label}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Use Workday Temp File Functionality
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={transport?.Use_Workday_Temp_File_Functionality}
                    onChange={handleChange(
                      "Use_Workday_Temp_File_Functionality"
                    )}
                  >
                    <option value="">Select</option>
                    {TECHNICAL.map((transport) => (
                      <option value={transport.key}>{transport.label}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Delivery Transport- Production
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={transport?.Delivery_Transport_Production}
                    onChange={handleChange("Delivery_Transport_Production")}
                  >
                    <option value="">Select</option>
                    {DELIVERY_TRANSPORT.map((transport) => (
                      <option value={transport.key}>{transport.label}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Address/URL - Production
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder=" "
                    value={transport?.AddressorURL_Production}
                    onChange={handleChange("AddressorURL_Production")}
                  />
                    {errors?.AddressorURL_Production && (
                      <p className="error-text">{errors?.AddressorURL_Production}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Directory- Production
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder=" "
                    value={transport?.Directory_Production}
                    onChange={handleChange("Directory_Production")}
                  />
                    {errors?.Directory_Production && (
                      <p className="error-text">{errors?.Directory_Production}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Username- Production
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder=" "
                    value={transport?.Username_Production}
                    onChange={handleChange("Username_Production")}
                  />
                    {errors?.Username_Production && (
                      <p className="error-text">{errors?.Username_Production}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Authentication- Production
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder=" "
                    value={transport?.Authentication_Production}
                    onChange={handleChange("Authentication_Production")}
                  />
                     {errors?.Authentication_Production && (
                      <p className="error-text">{errors?.Authentication_Production}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Delivery Transport- Test
                  </label>

                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={transport?.Delivery_Transport_Test}
                    onChange={handleChange("Delivery_Transport_Test")}
                  >
                    <option value="">Select</option>
                    {DELIVERY_TRANSPORT.map((transport) => (
                      <option value={transport.key}>{transport.label}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Address/URL- Test
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder=" "
                    value={transport?.AddressorURL_Test}
                    onChange={handleChange("AddressorURL_Test")}
                  />
                     {errors?.AddressorURL_Test && (
                      <p className="error-text">{errors?.AddressorURL_Test}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Directory- Test
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder=" "
                    value={transport?.Directory_Test}
                    onChange={handleChange("Directory_Test")}
                  />
                   {errors?.Directory_Test && (
                      <p className="error-text">{errors?.Directory_Test}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Username- Test
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder=" "
                    value={transport?.Username_Test}
                    onChange={handleChange("Username_Test")}
                  />
                   {errors?.Username_Test && (
                      <p className="error-text">{errors?.Username_Test}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Authentication- Test
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder=" "
                    value={transport?.Authentication_Test}
                    onChange={handleChange("Authentication_Test")}
                  />
                   {errors?.Authentication_Test && (
                      <p className="error-text">{errors?.Authentication_Test}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Document Retention Period
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder=" "
                    value={transport?.Document_Retention_Period}
                    onChange={handleChange("Document_Retention_Period")}
                  />
                   {errors?.Document_Retention_Period && (
                      <p className="error-text">{errors?.Document_Retention_Period}</p>
                    )}
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Encryption Required ?
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={transport?.Encryption_Required}
                    onChange={handleChange("Encryption_Required")}
                  >
                    <option value="">Select</option>
                    {TECHNICAL.map((transport) => (
                      <option value={transport.key}>{transport.label}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Delete file after retrieval (applicable for inbound)
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={
                      transport?.Delete_file_after_retrieval_applicable_for_inbound_integration
                    }
                    onChange={handleChange(
                      "Delete_file_after_retrieval_applicable_for_inbound_integration"
                    )}
                  >
                    <option value="">Select</option>
                    {TECHNICAL.map((transport) => (
                      <option value={transport.key}>{transport.label}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group ">
                  <label htmlFor="name" className="form-label customername">
                    Notes
                  </label>
                  <textarea
                    id="story"
                    name="story"
                    value={transport?.Notes}
                    onChange={handleChange("Notes")}
                    className="form-textarea"
                  >
                    {" "}
                  </textarea>
                  {errors?.Notes && (
                      <p className="error-text">{errors?.Notes}</p>
                    )}
                </div>
                <div className="btn-block">
                  <button type="submit" className="thame-btn w-100">
                    Update
                  </button>
                </div>
              </div>

              {/* <div className="col-md-2">
                <div className="testcase_list_wrapper">
                  <div className="test_case_list">
                    <div className="test_case_list pt-4">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#testcaselist"
                      >
                        test
                      </a>
                    </div>

                    <div className="test_case_list pt-5">
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#testcaselistfill"
                      >
                        test 2
                      </a>
                    </div>
                    <div className="test_case_list mt-3">
                      <a href="#">3</a>
                    </div>
                    <div className="test_case_list mt-1">
                      <a href="#">4</a>
                    </div>
                    <div className="test_case_list ">
                      <a href="#">5</a>
                    </div>
                    <div className="test_case_list mt-2">
                      <a href="#">6</a>
                    </div>
                    <div className="test_case_list ">
                      <a href="#">7</a>
                    </div>
                    <div className="test_case_list">
                      <a href="#">8</a>
                    </div>
                    <div className="test_case_list mt-2">
                      <a href="#">9</a>
                    </div>
                    <div className="test_case_list mt-1">
                      <a href="#">10</a>
                    </div>
                    <div className="test_case_list">
                      <a href="#">11</a>
                    </div>
                    <div className="test_case_list mt-1">
                      <a href="#">12</a>
                    </div>
                    <div className="test_case_list ">
                      <a href="#">13</a>
                    </div>
                    <div className="test_case_list mt-2">
                      <a href="#">14</a>
                    </div>
                    <div className="test_case_list ">
                      <a href="#">15</a>
                    </div>
                    <div className="test_case_list mt-4">
                      <a href="#">16</a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </div>
      <CustomModal
        title="Requirement Transport"
        onOpen={errorPopup}
        content={"Error!Please try again"}
        modalType={"error"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setErrorPopup(false);
        }}
      />
      <CustomModal
        title="Requirement Transport"
        onOpen={successPopup}
        content={"Requirement Transport data saved!"}
        modalType={"success"}
        width={"sm"}
        OnClickCloseIcon={() => {
          setSuccessPopup(false);
        }}
      />
    </div>
  );
};

export default Transport;
