import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { TASK_STATUS } from "../../../constants/common";
import { formatDate } from "../../../utility/idelTimer";

const commonColumnsDef = {
  headerClassName: "super-app-theme--header",
  sortable: false,
  flex: 1,
};

const columns = () => {
  return [
    {
      field: "project_name",
      headerName: "Project",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.Project?.project_level === "Sub_Project" ? (
              <Tooltip title={params?.row?.Project?.project_name} followCursor>
                <Link
                  to={`/project/manage-sub-project/${params?.row?.Project?.master_project}/${params?.row?.Project?.company_customer_id}/${params?.row?.Project?.id}`}
                  state={{
                    masterProjectId: params?.row?.Project?.master_project,
                    companyCustomerId:
                      params?.row?.Project?.company_customer_id,
                    subProjectId: params?.row?.Project?.id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.Project?.project_name}
                </Link>
              </Tooltip>
            ) : (
              <Tooltip title={params?.row?.Project?.project_name} followCursor>
                <Link
                  to={`/project/${params?.row?.Project?.id}/${params?.row?.Project?.company_customer_id}`}
                  state={{
                    projectId: params?.row?.Project?.id,
                    customerId: params?.row?.Project?.company_customer_id,
                  }}
                  style={{ color: "#1976d2" }}
                >
                  {params?.row?.Project?.project_name}
                </Link>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "project_status",
      headerName: "Status",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        let obj = TASK_STATUS.find(
          (o) => o.key === params?.row?.Project?.project_status
        );
        return obj?.label || "NA";
      },
    },
    {
      field: "role",
      headerName: "Project Role",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => params?.row?.role || "NA",
    },
    {
      field: "start_date",
      headerName: "Start Date",
      minWidth: 115,
      ...commonColumnsDef,
      // valueFormatter: (params: any) => {
      //   return params?.value === undefined
      //     ? "NA"
      //     : moment(params?.value).format("YYYY-MM-DD");
      // },
      renderCell: (params: any) => {        
        return (
          <>
            {
              params?.row?.Project?.ProjectDate?.plan_phase_start_date 
                ? formatDate(params?.row?.Project?.ProjectDate?.plan_phase_start_date)
                : "NA"
            }
          </>
        );
      },
    },
    {
      field: "end_date",
      headerName: "End Date",
      minWidth: 115,
      ...commonColumnsDef,
      renderCell: (params: any) => {        
        return (
          <>
            {
              params?.row?.Project?.ProjectDate?.hypercare_end_date 
                ? formatDate(params?.row?.Project?.ProjectDate?.hypercare_end_date)
                : "NA"
            }
          </>
        );
      },
    },
    // {
    //   field: "total_pool_hours",
    //   headerName: "Hours",
    //   minWidth: 115,
    //   ...commonColumnsDef,
    //   renderCell: (params: any) =>
    //     // params?.row?.Project?.total_pool_hours || "NA",
    //     // "NA"
    // },
    /*{
      field: "actions",
      type: "actions",
      headerName: "Action",
      minWidth: 152,
      ...commonColumnsDef,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/project/${params?.row?.id}`}
            state={{ customerId: params?.row?.id }}
          >
            <EditIcon sx={{ color: "#B4B2B2" }} />
          </Link>
        );
      },
    },*/
  ];
};

export default columns;
