import axios from "axios";
import { userLogoutSuccess, LogoutUser } from "../store/actions/auth";
import { Navigate, redirect, useNavigate } from "react-router-dom";

function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem("accessToken");
  return accessToken;
}
function getLocalRefreshToken() {
  const refreshToken = window.localStorage.getItem("refreshToken");
  return refreshToken;
}

//Base URL test
export const BASE_URL="https://inuci.deloitte.com/workday-prod";


export const apiClient = axios.create({
  baseURL: BASE_URL,
  // timeout: 30,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
  withCredentials: true,
});

interface StoreType {
  dispatch: any;
  getState: CallableFunction;
}

interface ConfigType {
  headers: {
    Authorization: string;
  };
}

let store: StoreType;

export const setStore = (storeInstance: StoreType): void => {
  store = storeInstance;
};

export const getStore = (): StoreType => {
  return store;
};

export const getGlobalStore = () => {
  return store;
};
apiClient.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      //config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (res) => {
    // console.log("Before Interceptor Response -----> ", res?.status);
    // if (res?.status == 302) {
    //   debugger;
    //   store?.dispatch(
    //     userLogoutSuccess(() => {
    //       window.location = "/login";
    //     })
    //   );

    // }
    // console.log("After Interceptor Response -----> ", res);
    //console.log("response after logout",res)
    if (res?.data === "Logged Out Successfully.") {
      store?.dispatch(
        userLogoutSuccess(() => {
          window.location.href = "/Login";
        })
      );
    }
    return res;
  },

  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      // if (err.response.status === 401 && !originalConfig._retry) {
      //   originalConfig._retry = true;
      //   try {
      //     const rs = await refreshToken();
      //     const { accessToken } = rs.data;
      //     window.localStorage.setItem("accessToken", accessToken);
      //     apiClient.defaults.headers.common["x-access-token"] = accessToken;
      //     return apiClient(originalConfig);
      //   } catch (_error) {
      //     console.log("iiiiiiiiiii", _error);
      //     if (_error?.response && _error?.response.data) {
      //       return Promise.reject(_error?.response.data);
      //     }
      //     return Promise.reject(_error);
      //   }
      // }

      console.log("Interceptor Error -----> ", err);
      console.log("status", err.response.status, err.response.data);
      // if (err.response.status === 403 && err.response.data) {
      //return Promise.reject(err.response.data);
      // }
      if (err.response.status === 401) {
        console.log("Interceptor Error inside", err);
        await store?.dispatch(
          userLogoutSuccess(() => {
            console.log("should redirect to login");
            // window.location.href = "/Login";
          })
        );
        window.location.href = "/Login";
      }
      // else if (err.response.status === 500) {
      //   await store?.dispatch(
      //     userLogoutSuccess(() => {
      //       console.log("should redirect to login")
      //     })
      //   );
      //   window.location.href = "/Login";
      // } 
      // else if (err.code === "ERR_NETWORK") {
      // console.log("Interceptor Error Network", err);
      // store?.dispatch(
      //   userLogoutSuccess(() => {
      //     window.location = "/login";
      //   })
      // );
    } else {
      // store?.dispatch(
      //   userLogoutSuccess(() => {
      //     window.location = "/login";
      //   })
      // );
    }

    return Promise.reject(err);
  }
);

export const queryGenerator = (data) => {
  let query = [];
  if (data && typeof data === "object" && Object.keys(data).length) {
    Object.keys(data).forEach((key) => {
      query.push(`${key}=${encodeURIComponent(data[key])}`);
    });
  }
  return query.join("&");
};
function signin() {
  return apiClient.post("/auth/signin", {
    username: "zkoder",
    password: "12345678",
  });
}
function refreshToken() {
  return apiClient.post("/auth/refreshtoken", {
    refreshToken: getLocalRefreshToken(),
  });
}
export default {
  get: async (url, config = {}) => {
    try {
      const urlNew = BASE_URL + url;
      return await apiClient.get(urlNew, config);
    } catch (ex) {
      return ex.response;
    }
  },
  post: async (url, data, config = {}) => {
    try {
      const urlNew =BASE_URL + url;
      // const result = await apiClient.post(urlNew, data, config);
      //  console.log(`bbbbbbbbb ${result}`)
      return await apiClient.post(urlNew, data, config);
      // return result
    } catch (ex) {
      return ex.response;
    }
  },
  delete: async (url, config = {}) => {
    try {
      const params = config?.data || {};
      const headers = {
        Authorization: null,
        "Content-Type": "application/json",
      };
      const urlNew = BASE_URL + url;
      return await apiClient.delete(urlNew, {
        params,
        headers,
      });
    } catch (ex) {
      return ex.response;
    }
  },
  getPublic: async (url, config = {}) => {
    try {
      const urlNew = BASE_URL + url;
      return await apiClient.get(urlNew, config);
    } catch (ex) {
      return ex.response;
    }
  },
  putPublic: async (url, data, config = {}) => {
    try {
      const urlNew =BASE_URL + url;
      return await apiClient.put(urlNew, data, config);
    } catch (ex) {
      return ex.response;
    }
  },
  putS3: async (url, data, config) => {
    try {
      // const urlNew = "http://localhost:8000" + url;
      return await apiClient.put(url, data, config);
    } catch (ex) {
      throw ex;
    }
  },

  postPublic: async (url, data, config = {}) => {
    try {
      const urlNew = BASE_URL + url;
      //  console.log(data)
      // ;
      //  return await apiClient.post(urlNew, data, config);
      const result = await apiClient.post(urlNew, data, config);
      console.log(`bbbbbbbbb ${result}`);

      return result;
    } catch (ex) {
      return ex.response;
    }
  },
};
