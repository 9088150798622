import { AnyAction } from "@reduxjs/toolkit";
import apiClient from "../apiClient";
import { END_POINTS } from "../common/endpoint";

export const getMyTasksData = async (payload: object) => {
  console.log("getMyTasksData--->", payload);
  return await apiClient.post(
    END_POINTS.GET_MY_TASKS +
      `?task_status=In_Progress&project_id=${payload?.project_id}`,
    payload
  );
};

// export const getTasksData = async (payload: object) => {
//   let epURL = END_POINTS.GET_MY_TASKS.replace(":page", payload.page);
//   epURL = epURL.replace(":size", payload.size);

//   return await apiClient.post(epURL, payload);
// };
export const getTasksData = async (payload: object) => {
  return await apiClient.post(END_POINTS.GET_MY_TASKS, payload);
};

export const getTaskByCustomerData = async (payload: any) => {
  const queryString = Object.entries(payload)
    .filter(([_, value]) => value !== null)
    .map(([key, value]) =>
      value !== undefined ? `${key}=${value}` : undefined
    )
    .join("&");

  return await apiClient.get(
    END_POINTS.GET_TASK_BY_CUSTOMER_PROJECT + `?${queryString}`
  );
};

export const getTaskProgressData = async (payload: object, task_id: any) => {
  return await apiClient.get(
    END_POINTS.GET_TASK_PROGRESS.replace(":id", task_id),
    payload
  );
};

export const updateTaskProgressData = async (payload: object, task_id: any) => {
  return await apiClient.post(
    END_POINTS.UPDATE_TASK_PROGRESS.replace(":id", task_id),
    { payload }
  );
};

export const getTaskByCustomerProjectHealth = async (payload: object) => {
  const queryString = Object.entries(payload)
    .filter(([_, value]) => value !== null)
    .map(([key, value]) =>
      value !== undefined ? `${key}=${value}` : undefined
    )
    .join("&");
  console.log(
    END_POINTS.GET_TASK_BY_CUSTOMER_PROJECT_HEALTH + `?${queryString}`
  );
  return await apiClient.get(
    END_POINTS.GET_TASK_BY_CUSTOMER_PROJECT_HEALTH + `?${queryString}`
  );
};

export const getTaskByCustomerProjectHealthRatio = async (payload: object) => {
  const queryString = Object.entries(payload)
    .filter(([_, value]) => value !== null)
    .map(([key, value]) =>
      value !== undefined ? `${key}=${value}` : undefined
    )
    .join("&");
  console.log(
    END_POINTS.GET_TASK_BY_CUSTOMER_PROJECT_HEALTH_RATIO + `?${queryString}`
  );
  return await apiClient.get(
    END_POINTS.GET_TASK_BY_CUSTOMER_PROJECT_HEALTH_RATIO + `?${queryString}`
  );
};
