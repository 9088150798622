import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const Healthevpv = (props: any) => {
  console.log("Props", props, props.labelTarget.length, props.labelTarget[0]);
  let colorBackground =
    props.labelTarget.length === 1 && props.labelTarget[0] === "NA"
      ? ["#53565A"]
      : [
          "#ED8B00",
          "#FFCD00",
          "#33f0f",
          "#046A38",
          "#007680",
          "#2C5234",
          "#012169",
          "#041E42",
          "#00ABAB",
          "#26890D",
          "#005587",
          "#53565A",
          "#DA291C",
          "#8A2BE2",
          "#800080",
          "#9400D3",
          "#EE82EE",
          "#808000",
          "#00BFFF",
          "#7B68EE",
        ];
  let data = {
    labels: props.labelTarget.slice(0, 20),
    datasets: [
      {
        label: "# of Votes",
        data: props.valueTarget.slice(0, 20),
        backgroundColor: colorBackground,
        borderColor: colorBackground,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      style={{
        height: 500,
        width: 500,
        textAlign: "center",
      }}
    >
      <Doughnut data={data} />
    </div>
  );
};

export default Healthevpv;
