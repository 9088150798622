import "./App.scss";

import Login from "./pages/Login/login";
import { Container, Grid } from "@mui/material";
//import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { browserHistory } from "react-router";
import Dashboard from "./pages/dashboard";
import withNavigation from "./components/Nav/withNavigation";
import Register from "./pages/Register/register";
import PasswordReset from "./pages/PasswordReset/passwordReset";
import PasswordSuccess from "./pages/PasswordReset/passwordSuccess";
import EmailSuccess from "./pages/PasswordReset/emailSuccess";
import Customer from "./pages/Customer";
import AllCustomerList from "./pages/Customer/AllCustomer";
import Project from "./pages/Projects";
import SubProjectModule from "./pages/Projects/SubProjectModule";
import TaskLanding from "./pages/Tasks";
import { useGlobalSelector } from "./store/context";
import { useDispatch, useSelector } from "react-redux";

import MyProjectList from "./pages/Projects/MyProjects";
import AllProjectsList from "./pages/Projects/AllProjects";
import ProjectByCustomerList from "./pages/Projects/ProjectByCustomer";
import CustomLoader from "./components/CustomLoader";
import { loaderRef } from "./components/CustomLoader/loaderState";
import RegisterForm from "./pages/Register/register";
import PrivateRoute from "./components/Authentication/PrivateRoute";

import CustomHeader from "./components/CustomHeader";
import CustomFooter from "./components/CustomFooter";
import MyTasksList from "./pages/Tasks/MyTasks";
import TaskByCustomerList from "./pages/Tasks/TaskByCustomer";
import Requirement from "./pages/Requirement";
import ForgotPassword from "./pages/PasswordReset/forgot";
import User from "./pages/Register";
import ProjectHealth from "./pages/dashboard/ProjectHealth";
import ViewForm from "./pages/Register/viewPage";
import IntegrationRepository from "./pages/IntegrationRepository/integrationRepository";
import RequirementPdf from "./pages/Requirement/Print/Pdf";
import React, { useEffect, useRef } from "react";
import { ProfileType } from "./utility/commonTypes";
import TransitionDocument from "./components/CustomHeader/TransitionDocument";
import IdleTimer from "../src/utility/idelTimer";
import TimeoutWrapper from "./utility/timeoutService";
import { timeoutRef } from "./utility/timeoutService/timeoutService";
import NormalLogin from "./pages/Login/normalLogin";
import { MsalProvider } from "@azure/msal-react";

import { IPublicClientApplication } from "@azure/msal-browser";
import { LogOut } from "./pages/Login/logout";
import ReportIssue from "./components/CustomHeader/reportIssue";
import AllUsersList from "./pages/Users";
type AppProps = {
  pca: IPublicClientApplication;
};


const App = ({ pca }: AppProps) => {
  const LoginComponentWithNavigation = withNavigation(Login);
  const PasswordResetWithNavigation = withNavigation(PasswordReset);
  const ForgotPasswordWithNavigation = withNavigation(ForgotPassword);
  const PasswordSuccessWithNavigation = withNavigation(PasswordSuccess);
  const EmailSuccessWithNavigation = withNavigation(EmailSuccess);
  const CustomerComponentWithNavigation = withNavigation(Customer);
  const ProjectComponentWithNavigation = withNavigation(Project);
  const SubProjectComponent = withNavigation(SubProjectModule);
  const TaskComponentWithNavigation = withNavigation(TaskLanding);
  const RequirementComponentWithNavigation = withNavigation(Requirement);
  const RegisterComponentWithNavigation = withNavigation(User);
  const LogoutComponentWithNavigation=withNavigation(LogOut)


  const isLoggedIn = useSelector((store: any) => {
    return store.auth.isLoggedIn;
  });
const idleTimeChecker = useRef<IdleTimer>();

  console.log("window.location.pathname", window.location);
  const isPDFPrint =
    window.location.pathname.substring(0, 5) === "/pdf/" ? true : false;
    const getSessionTimeout = useSelector((store: any) => {
      console.log("session",store?.auth?.globalAuthData)

      return store.auth.globalAuthData?.sessionExp;
    });
    useEffect(() => {
      /**
       * Idle time checker
       */
      if (isLoggedIn) {
        const expDate = new Date(getSessionTimeout);
        const currentDate = new Date();
        const diff: any =
          (expDate.getTime() - currentDate.getTime()) / 1000 / 60;
          console.log("----Time------")
        idleTimeChecker?.current?.cleanup();
        idleTimeChecker.current = new IdleTimer({
          timeout: diff,
          
        
       
        });
      } else {
        idleTimeChecker?.current?.cleanup();
        const pathname = window.location.pathname;
        const publicRoutes = ['/', '/login'];
        if(publicRoutes.indexOf(pathname.toLowerCase()) == -1 && !isLoggedIn) {
          window.location.href = "/"
      }
    }
      return () => {
          idleTimeChecker?.current?.cleanup();
      };
  }, [isLoggedIn]);
  
  return (
    <>
            <MsalProvider instance={pca}>


      <Container
        maxWidth={false}
        className={`p-0 m-0 d-flex flex-column`}
        sx={{ backgroundColor: "#F5F6F7" }}
      >
        <CustomLoader ref={loaderRef} />
<TimeoutWrapper ref={timeoutRef}/>
        {isLoggedIn ? (
          <Router>
            {isPDFPrint ? null : <CustomHeader />}
            <Grid
              className={`shadow my-4 mx-3`}
              sx={{ backgroundColor: "white", minHeight: "100vh" }}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <PrivateRoute
                      component={Dashboard}
                      profiletype={[
                        ProfileType?.ADMIN,
                        ProfileType?.CUSTOMER,
                        ProfileType?.USER,
                      ]}
                    />
                  }
                />
                <Route
                  path="/Home"
                  element={
                    <PrivateRoute
                      component={Dashboard}
                      profiletype={[
                        ProfileType?.ADMIN,
                        ProfileType?.CUSTOMER,
                        ProfileType?.USER,
                      ]}
                    />
                  }
                />
                <Route
                  path="/pdf"
                  element={
                    <PrivateRoute
                      component={RequirementPdf}
                      profiletype={[
                        ProfileType?.ADMIN,
                        ProfileType?.CUSTOMER,
                        ProfileType?.USER,
                      ]}
                    />
                  }
                />
                <Route
                  path="/pdf/:projectId/:customerId/:taskId/:requirementId"
                  element={
                    <PrivateRoute
                      component={RequirementPdf}
                      profiletype={[
                        ProfileType?.ADMIN,
                        ProfileType?.CUSTOMER,
                        ProfileType?.USER,
                      ]}
                    />
                  }
                />
                <Route path="/register" element={<RegisterForm />} />
                <Route path="/user/view" element={<ViewForm />} />
                <Route path="/user/:userId" element={<ViewForm />} />

                <Route
                  path="/register"
                  element={<RegisterComponentWithNavigation />}
                />
                <Route
                  path="/passwordReset"
                  element={<PasswordResetWithNavigation />}
                />
                <Route
                  path="/forgotPassword"
                  element={<ForgotPasswordWithNavigation />}
                />
                <Route
                  path="/passwordSuccess"
                  element={<PasswordSuccessWithNavigation />}
                />
                <Route
                  path="/emailSuccess"
                  element={<EmailSuccessWithNavigation />}
                />
                <Route
                  path="/customer-create"
                  element={
                    <PrivateRoute
                      component={CustomerComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN]}
                    />
                  }
                />
                <Route
                  path="/customer"
                  element={
                    <PrivateRoute
                      component={CustomerComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER]}
                    />
                  }
                />
                <Route
                  path="/project-create"
                  element={
                    <PrivateRoute
                      component={ProjectComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN]}
                    />
                  }
                />
                <Route
                  path="/transition"
                  element={
                    <PrivateRoute
                      component={TransitionDocument}
                      profiletype={[ProfileType?.ADMIN]}
                    />
                  }
                />
                 <Route
                  path="/report"
                  element={
                    <PrivateRoute
                      component={ReportIssue}
                      profiletype={[ProfileType?.ADMIN]}
                    />
                  }
                />
                <Route
                  path="/project"
                  element={
                    <PrivateRoute
                      component={ProjectComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/project/manage-sub-project/:id"
                  element={
                    <PrivateRoute
                      component={SubProjectComponent}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />

                <Route
                  path="/project/manage-sub-project/:masterProjectId/:companyCustomerId"
                  element={
                    <PrivateRoute
                      component={SubProjectComponent}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />

                <Route
                  path="/project/manage-sub-project/:masterProjectId/:companyCustomerId/:subProjectId"
                  element={
                    <PrivateRoute
                      component={SubProjectComponent}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/project/:customerId"
                  element={
                    <PrivateRoute
                      component={ProjectComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/project/:id"
                  element={
                    <PrivateRoute
                      component={ProjectComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/project/:projectId/:customerId"
                  element={
                    <PrivateRoute
                      component={ProjectComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/projects-by-customer"
                  element={
                    <PrivateRoute
                      component={ProjectByCustomerList}
                      profiletype={[ProfileType?.ADMIN]}
                    />
                  }
                />
                <Route
                  path="/customer/:customerId"
                  element={
                    <PrivateRoute
                      component={CustomerComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER]}
                    />
                  }
                />
                <Route
                  path="/customer/:customerId/:companyId"
                  element={
                    <PrivateRoute
                      component={CustomerComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER]}
                    />
                  }
                />
                <Route
                  path="/create-task"
                  element={
                    <PrivateRoute
                      component={TaskComponentWithNavigation}
                      profiletype={[
                        ProfileType?.ADMIN,
                        ProfileType?.CUSTOMER,
                        ProfileType?.USER,
                      ]}
                    />
                  }
                />
                <Route
                  path="/create-update-task"
                  element={
                    <PrivateRoute
                      component={TaskComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/create-update-task/:id"
                  element={
                    <PrivateRoute
                      component={TaskComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/create-update-task/:projectId/:customerId"
                  element={
                    <PrivateRoute
                      component={TaskComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/create-update-task/:projectId/:customerId/:taskId"
                  element={
                    <PrivateRoute
                      component={TaskComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/requirement"
                  element={
                    <PrivateRoute
                      component={RequirementComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER]}
                    />
                  }
                />
                <Route
                  path="/requirement/:id"
                  element={
                    <PrivateRoute
                      component={RequirementComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/requirement/:projectId/:customerId"
                  element={
                    <PrivateRoute
                      component={RequirementComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/requirement/:projectId/:customerId/:taskId"
                  element={
                    <PrivateRoute
                      component={RequirementComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/requirement/:projectId/:customerId/:taskId/:requirementId"
                  element={
                    <PrivateRoute
                      component={RequirementComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                <Route
                  path="/requirement/:id"
                  element={
                    <PrivateRoute
                      component={RequirementComponentWithNavigation}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER,ProfileType?.CUSTOMER]}
                    />
                  }
                />
                {/* My Projects */}
                <Route
                  path="/my-projects"
                  element={
                    <PrivateRoute
                      component={MyProjectList}
                      profiletype={[
                        ProfileType?.ADMIN,
                        ProfileType?.CUSTOMER,
                        ProfileType?.USER,
                      ]}
                    />
                  }
                />
                 <Route
                  path="/all-users"
                  element={
                    <PrivateRoute
                      component={AllUsersList}
                      profiletype={[ProfileType?.ADMIN]}
                    />
                  }
                />
                <Route
                  path="/all-projects"
                  element={
                    <PrivateRoute
                      component={AllProjectsList}
                      profiletype={[ProfileType?.ADMIN]}
                    />
                  }
                />

                <Route
                  path="/my-tasks"
                  element={
                    <PrivateRoute
                      component={MyTasksList}
                      profiletype={[
                        ProfileType?.ADMIN,
                        ProfileType?.CUSTOMER,
                        ProfileType?.USER,
                      ]}
                    />
                  }
                />
                <Route
                  path="/all-customers"
                  element={
                    <PrivateRoute
                      component={AllCustomerList}
                      profiletype={[ProfileType?.ADMIN,ProfileType?.USER]}
                    />
                  }
                />
                <Route
                  path="/tasks-by-customer"
                  element={
                    <PrivateRoute
                      component={TaskByCustomerList}
                      profiletype={[ProfileType?.ADMIN]}
                    />
                  }
                />
                <Route
                  path="/resource-utilization-dashboard"
                  element={
                    <PrivateRoute
                      component={ProjectHealth}
                      profiletype={[ProfileType?.ADMIN]}
                    />
                  }
                />
                <Route path="/repository" element={<IntegrationRepository />} />
              </Routes>
            </Grid>

            {isPDFPrint ? null : <CustomFooter></CustomFooter>}
          </Router>
        ) : (
          <Router>
            <Routes>
              <Route path="/" element={<LoginComponentWithNavigation />} />
              <Route path="/login" element={<NormalLogin />}/>
              <Route path="/logout" element={<LogoutComponentWithNavigation />}/>



              <Route path="/Login" element={<LoginComponentWithNavigation />} />
            </Routes>
          </Router>
        )}
      </Container>
      </MsalProvider>
    </>
  );
};

export default App;
